.ewa-loader {

    &_basic {
        position:relative;

        &:after, &:before {
            content:"";
            display:block;
            position:absolute;
            top:50%;
            left:50%;
            transform:scale(1);

            border-radius:100%;
            border:1px #fff solid;

            animation-duration:1s;
            animation-iteration-count:infinite;
        }

        &:after {
            margin:-10px;
            height:20px;
            width:20px;

            border-color:$color-secondary;
            opacity:0;

            transform:scale(2);

            animation-name:ewa-loader-circle;
            animation-delay:0.3s;
        }

        &:before {
            margin:-18px;
            height:36px;
            width:36px;

            transform:scale(1.2);

            border-color:rgba($color-secondary, 0.2);
            animation-name:ewa-loader-circle;
        }
    }

    &_table {
        @extend .ewa-loader_basic;

        display:none;
        height:70px;
        background:$color-fill;

        &.-visible {
            display:block;
        }
    }

    &_overlay {
        @extend .ewa-loader_basic;

        display:none;
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:99;

        background:rgba($color-primary, 0.2);

        &:after {
            margin:-25px;
            height:50px;
            width:50px;

            border-color:white;
        }

        &:before {
            margin:-50px;
            height:100px;
            width:100px;

            border-color:white;
        }

        &.-visible {
            display:block;
        }
    }
}

@keyframes ewa-loader-circle {
    0% {
        opacity:1;
        transform:scale(0);
    }

    50% {
        opacity:1;
    }

    100% {
        opacity:0;
    }
}