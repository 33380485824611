.float {
    &.left {
        float: left;
    }
    &.right {
        float: right;
    }
    &.clear {
        clear: both;
    }
}

.align {
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}