.badge-lang {
  display: inline-block;
  margin: 2px;
  padding: 5px 3px;
  color: white;
  border-radius: 3px;
  background: #ccc;
  cursor: pointer;

  &:hover {
    background: lightgreen;
  }

  &.-success {
    background: lightgreen;
  }

  &.-denied {
    background: red;
  }
}

#modal-sources-edit {
  .fa-check {
    color: lightgreen;
  }

  .title {
    text-align: center;

    font-size: 30px;

    padding: 30px 0;
    border-bottom: solid 1px #ccc;
  }

  .sources {
    display: flex;
    flex-direction: column;

    .source-item {
      font-size: 22px;
      position: relative;

      padding: 10px 0;

      .wrapper {
        display: flex;
        position: relative;

        .source-container {
          display: flex;
          text-align: center;
          width: 100%;
          &:hover {
            background: $color-info;
          }
        }

        .source-expanded {
          text-align: center;
          width: 100%;
        }

        .actions-container {
          position: absolute;
          right: 51%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .source-expanded {
          display: flex;
          flex-direction: column;

          background: $color-bg;
          padding: 0 50px 40px 50px;
          border-bottom: solid 1px #ccc;

          label {
            min-width: 150px;
            color: black;
            font-weight: bold;
            margin: 0;

            padding: 15px;
          }

          input {
            padding: 15px;
            display: inline;
          }

          .translation {
            display: flex;
          }

          .translation-words {
            display: flex;
            flex-direction: column;

            .word {
              display: flex;
            }
          }
        }

      }

      .source-sentence {
        width: 100%;
        align-items: center;
        display: flex;
        border: solid 1px;
        padding: 0 20px;
      }

      .source-sentence-translation {
        width: 100%;
        align-items: center;
        display: flex;
        border: solid 1px;
        padding: 0 20px;
      }

      &:hover {
        background: $color-bg;
        cursor: pointer;
      }
      &.active {
        background: $color-bg;

        .actions-container {
          display: none;
        }
        .source-sentence {
          width: 100%;
          align-items: center;
          display: flex;
          border: solid 1px;
          padding: 0 20px;

        }

        .source-sentence-translation {
          width: 100%;
          align-items: center;
          display: flex;
          border: solid 1px;
          padding: 0 20px;
        }

      }
    }
  }

  .ewa-modal-header {
    .translate-select {
      background-color: #fff;
      background-image: none;
      border: none;
      font-size: 15px;
      border-bottom: 1px solid hsla(220, 5%, 87%, .3);
      box-shadow: none;
      height: 34px;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

      margin-right: 30px;
    }
  }

  .ewa-modal-container {
    padding: 0;
  }
}
