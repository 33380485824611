.experiments {
  padding-top: 7rem;

  .head-actions {
    text-align: right;
  }

  .test-wrapper {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .test-control-wrapper {
    display: flex;
  }
  
  .test-body-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .test-group {
    font-size: 16px;
    font-weight: 700;
    color: #9096A5;
  }

  .test-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
  }

  .test-title {
    font-size: 20px;
  }

  .test-card {
    position: relative;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 20px;
    font-size: 16px;
    width: 100%;
    display: flex;
    column-gap: 5px;
    align-items: center;

    .languages {
      display: inline-block;
      margin-right: 10px;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;
        display: block;
        line-height: initial;
      }
    }
    .group {
      position: absolute;
      top: 50%;
      right: 2%;

      transform: translateY(-50%);
      font-weight: 700;
      color: #9096a5;
    }
  }
}

#edit-experiment {
  #jsoneditor {
    height: 500px;
  }
}

#new-experiment {
  .languages {
    margin-top: 10px;
  }

  .language button {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
    white-space: nowrap;
  }

  .sides {
    text-align: center;
    display: flex;
    margin-bottom: 30px;

    .side {
      position: relative;
      flex: 1;

      .row {
        flex: 1;
      }

      .row-action {
        background: red;
        border-radius: 30px;
        display: inline-block;

        padding: 20px 62px;
        margin-top: 25px;

        cursor: pointer;

        &:hover {
          background: darkred;
        }

        i {
          font-size: 22px;
          color: white;
        }
      }
    }

    .middle-side {
      display: flex;
      flex: 0.1;

      align-items: center;
      vertical-align: center;

      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
