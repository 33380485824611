/* Lato 2.0 */
@font-face {
    font-family: 'LatoWeb';
    src: url('#{$path-assets-relative}fonts/lato/Lato-Regular.eot');
    src: url('#{$path-assets-relative}fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$path-assets-relative}fonts/lato/Lato-Regular.woff2') format('woff2'),
    url('#{$path-assets-relative}fonts/lato/Lato-Regular.woff') format('woff'),
    url('#{$path-assets-relative}fonts/lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$path-assets-relative}fonts/lato/Lato-Medium.eot');
    src: url('#{$path-assets-relative}fonts/lato/Lato-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$path-assets-relative}fonts/lato/Lato-Medium.woff2') format('woff2'),
    url('#{$path-assets-relative}fonts/lato/Lato-Medium.woff') format('woff'),
    url('#{$path-assets-relative}fonts/lato/Lato-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$path-assets-relative}fonts/lato/Lato-Semibold.eot');
    src: url('#{$path-assets-relative}fonts/lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
    url('#{$path-assets-relative}fonts/lato/Lato-Semibold.woff2') format('woff2'),
    url('#{$path-assets-relative}fonts/lato/Lato-Semibold.woff') format('woff'),
    url('#{$path-assets-relative}fonts/lato/Lato-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$path-assets-relative}fonts/lato/Lato-Bold.eot');
    src: url('#{$path-assets-relative}fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$path-assets-relative}fonts/lato/Lato-Bold.woff2') format('woff2'),
    url('#{$path-assets-relative}fonts/lato/Lato-Bold.woff') format('woff'),
    url('#{$path-assets-relative}fonts/lato/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('#{$path-assets-relative}fonts/lato/Lato-Heavy.eot');
    src: url('#{$path-assets-relative}fonts/lato/Lato-Heavy.eot?#iefix') format('embedded-opentype'),
    url('#{$path-assets-relative}fonts/lato/Lato-Heavy.woff2') format('woff2'),
    url('#{$path-assets-relative}fonts/lato/Lato-Heavy.woff') format('woff'),
    url('#{$path-assets-relative}fonts/lato/Lato-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}