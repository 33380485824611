.uiview{
    width: 100%;
    height: 100%;
    overflow:hidden;
}

.-chain{
    display: table-row!important;
}

.-a-disabled{
    text-decoration: none!important;
    cursor: default!important;
    pointer-events: none!important;
}
.-a-disabled:hover{
    text-decoration: none!important;
    cursor: default!important;
    pointer-events: none!important;
}

.ewa-word:after{
    content:" "
}

.ewa-form-submit-error-color{
    color: #FD98A7;
}

.-pointer{
    cursor: pointer;
}

.ewa-word-image-file{
    width: 0;
    height: 0;
}

#newSrtFileControl{
    width: 0;
    height: 0;
}

.green-bg{
    background: #00C7AE;
    transition: background 0.2s;
    &:hover{
        background: #11D8BF;
    }
}

.-active-self:before{
    border: 2px #00C7AF solid!important;
}

.-float-right{
    float: right;
}

.-z-index-visible{
    z-index: 2;
}

.tooltip{
    position: fixed;
}

.-inline-block{
    display: inline-block!important;
}

.ewa-table_main tbody tr.-disabled td {
    opacity: 0.6!important;
}

.ewa-table_main tbody td.-actions > .-restore {
    display: inline-block;
}

.body-login, .body-restore, .body-reset {

}

.ewa-table_main tbody td.lbl-success,
.lbl-success {
  color: $color-success;
}

.lbl-failure {
  color: $color-error;
}

.muted {
    color: $color-smooth;
}

.pointer {
    cursor: pointer;
}

.lowercase {
    text-transform: lowercase;
}

.success-bg-highlight {
    background-color: $color-highlight-info;
}
