.ewa-modal {
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:1100;
    display:none;
    overflow:hidden;
    overflow-y:scroll;
    text-align:center;
    outline:0;

    &:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }

    .items {
        flex-direction: column;
    }

    &-dialog {
        position:relative;
        display:inline-block;
        width:350px;
        margin:0 auto;
        text-align:left;
        vertical-align:middle;

        .columns {
            display: flex;
            .column {

                label {
                    text-align: center;
                    font-weight: bold;
                    font-size: 20px;
                }

                display: flex;
                flex-direction: column;

                width: 100%;

                .column-item {
                    border-left: solid 1px #ccc;
                    border-right: solid 1px #ccc;

                    margin: 10px;
                    display: flex;
                    flex-direction: column;

                    text-align: center;

                    .lang {
                        font-size: 20px;
                        font-weight: bold;
                    }

                    textarea {
                        padding: 0;
                        height: 70px;
                        text-align: center;
                        resize: none;
                    }
                }
            }
        }

        &.-richTextEditor {
          width: 830px;
        }

        &.word-edit {
            width: 500px;
        }

        &.-lg {
            width:410px;
        }

        &.-wide {
            width: 800px;
        }

        &.-ultra-wide {
            width: 80%;
        }

        &.-localize {
            font-size: 13px;
            vertical-align: middle;

            input {
                font-size: 13px;
            }

            label {
                width: 100%;
                font-size: 13px;

                div {
                    height: 30px;
                    padding-right: 10px;

                    display: flex;
                    flex-flow: row nowrap;
                }

                .language-name {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    border-bottom: 1px soild #ccc;

                    i {
                        margin-right: 5px;
                        height: 15px;
                        width: 15px;
                    }
                }

                .pluralized {
                    display: flex;
                    flex-flow: row nowrap;
                }

                input {
                    margin-right: 5px;
                }
            }
        }
    }

    &-content {
        position:relative;
        overflow:hidden;

        background:#fff;
        border-radius:4px;

        &-typeahead {
            overflow: visible;
        }

        &-contrast {
            background: rgba($color: #000000, $alpha: 0.05);
        }
    }

    &-header {
        display:table;
        width:100%;
        height:45px;
        padding:4px 20px 0 20px;

        background:$color-fill;

        &-title {
            display:table-cell;
            color:$color-smooth;
            font-size:13px;
            font-weight:bold;
            letter-spacing:1.03px;
            text-transform:uppercase;
            vertical-align:middle;
        }

        &-actions {
            display: table-cell;
            vertical-align: middle;
            text-align: right;

            a {
                color:$color-smooth;
                font-size:12px;
                transition:color 0.2s;

                &:hover, &:focus {
                    color:$color-danger;
                    text-decoration:none;
                }
            }

            .ewa-icon {
                vertical-align: middle;
            }

            .trackers {
                margin-right: 5px;
                line-height: 15px;
                font-size: 13px;
                vertical-align: middle;

                margin-right: 5px;
                padding-bottom: 2px;

                cursor: pointer;
                user-select: none;

                &.applied {
                    color: $color-success;
                }
            }

            button {
                margin-right: 5px;
                height: 28px;
                line-height: 28px;
                font-size: 14px;
            }
        }
    }

    &-languages {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        padding: 5px 20px 0 20px;

        background: #fff;
        border-bottom: 1px solid $color-fill;

        line-height: 30px;

        .ewa-badge_lang {
            margin-bottom: 10px;
        }
    }

    &-body {
        &-scrollable {
            max-height: 450px;
            max-width: max-content;
            overflow-y: scroll;
        }
    }

    &-wrap {
        padding:20px 0;
    }

    &-container {
        padding:0 20px;

        .items {
            column-gap: 10px;
            h3 {
                padding-top: 2rem;
            }
            .item {
                clear: both;
                position: relative;
            }
            .remove {
                position: absolute;
                right: -10px;
                top: 50%;
            }
            .add {
                position: absolute;
                right: -5px;
                top: 60%;
                cursor: pointer;
            }
        }

        &-box form {
            display: flex;
            flex-flow: column nowrap;

            .options-wrapper {
                display: flex;
                flex-flow: row nowrap;

                border-bottom: solid 1px #ccc;

                &.wrap {
                    flex-flow: wrap;

                    .item, .item input {
                        text-align: center;
                    }
                }

                &.scrollable {
                    max-height: 40rem;
                    overflow-y: scroll;
                }

                .column {
                    flex-flow: column nowrap;
                    flex: 1;

                    &:first-child {
                        border-right: 1px solid #ccc;
                    }

                    padding: 0 20px;
                    min-height: 600px;
                }
            }

            .actions-wrapper {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                border-top: 1px solid #ccc;
                padding: 0 20px;

                .ewa-form-group {
                    flex: 1;
                    max-width: 250px;
                }
            }

            .tracker {
                &:first-child {
                    margin-top: 10px;
                }

                .tracker-name {
                    font-size: 16px;
                    padding: 10px 0;
                }
            }
        }

        &-wrapped {
            padding: 20px;

            &.-scroll-h {
                overflow-x: scroll;
                overflow-y: hidden;
            }
        }
    }

    &-alert {
        color:$color-disabled;

        &-sprite {
            margin:30px auto;
            text-align:center;
        }

        &-icon {
            display:table;
            width:106px;
            height:106px;
            margin:30px auto;

            border:2px $color-disabled solid;
            border-radius:100%;

            font-size:28px;

            i {
                display:table-cell;
                vertical-align:middle;
                text-align:center;
            }
        }

        &.-success &-icon {
            border-color:$color-success;
            color:$color-success;
        }

        &.-error &-icon {
            border-color:$color-error;
            color:$color-error;
        }

        &-text {
            margin:30px auto;

            font-size:16px;
            font-weight:normal;
            line-height:21px;
            text-align:center;
            overflow-wrap: anywhere;

            h1, h2, h3, h4, h5 {
                padding:0;
                margin:0 0 7px 0;
                font-size:16px;
                font-weight:bold;
            }

            p {
                margin:0;
                padding:0;
            }

            strong {
              color: red;
            }
        }
    }

    &-heading {
        font-size:16px;
        letter-spacing:0;

        h3 {
            margin:0;
            padding:0;
            font-size:18px;
        }

        p {
            margin:0;
            padding:0;
        }

        &-col {
            display:inline-block;
            vertical-align:middle;
        }
    }

    &-middleheader {
        height:45px;
        margin:0 -20px;
        padding:0 20px;

        background:$color-fill;
        color:$color-smooth;

        font-size:13px;
        font-weight:bold;
        letter-spacing:1.03px;
        line-height:45px;
        text-transform:uppercase;
    }

    &.sectioned-modal {
        .ewa-modal-dialog.-wide {
            width: 90%;
        }

        .ewa-modal-content {
            min-height: 400px;
        }

        .ewa-modal-container-box form {
            display: flex;
            flex-flow: row nowrap;
            padding-bottom: 10px;
        }

        .modal-section {
            margin-top: 5px;
            padding: 10px 15px;
            display: flex;
            flex-flow: column nowrap;
            flex: 1;
            border-right: 1px solid #ccc;
            min-height: 400px;
        }

        .ewa-form-group {
            margin: 3px 0;

            .ewa-form-title {
                font-weight: bold;
                font-size: 14px;
                margin: 3px 0;
            }

            label, input, select {
                font-size: 13px;
                margin-left: 10px;
            }
        }

        .ewa-form-json-editor {
            height: 560px;
        }

        .subscriptions-list {
            height: 226px;
            overflow-x: auto;
            list-style-type: none;
            padding-left: 0;

            li {
                line-height: 14px;
                height: 14px;
                vertical-align: middle;
            }

            input, span {
                vertical-align: middle;
                line-height: 14px;
                padding: 0 4px;
            }
        }
    }

    .trigger-container {
        width: 100%;

        &:hover {
            h4 {
                text-decoration: underline;
            }
        }

        h4 {
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            .send-button {
                cursor: pointer;
                color: transparentize(#000, .5)
            }
        }
    }
}

#modal-word-edit {
    .ewa-modal-header {
        position: relative;

        .languages {
            position: absolute;
            right: 0;
            top: 5px;
        }
    }

    #ewa-word-transcription {
        width: 100%;
    }

    .ewa-modal-heading-col {
        width: 100%;

        h3 {
            text-align: center;
        }
    }

    .item {
        padding: 0 15px;
        position: relative;

        .remove {
            position: absolute;
            right: -20px;
            top: 60px;

            cursor: pointer;
        }
    }

    .row {
        display: flex;

        margin-top: 25px;
    }
    .col {
        width: 100%;

        &:first-child{
            border-right: 1px solid $color-fill;
        }

        textarea {
            resize: none;
        }

        .ewa-form-group-remove {
            right: 10px;
            font-size: 15px;
        }
    }
}

#modal-builder {
    .ewa-modal-header-actions {
        i {
            font-size: 20px!important;
        }
    }

    .language button {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 120px;
        white-space: nowrap;

    }
}
