.ewa-word {
    cursor:pointer;
    position:relative;
    display:inline-block;
    min-width:60px;
    margin:4px 0;
    line-height:26px;
    text-align:center;
    word-break:break-all;

    background:$color-fill;
    border:1px $color-fill solid;
    border-radius:4px;

    &-input {
        display:inline-block;
        padding:0 8px;

        font-size:14px;
        letter-spacing:0.14px;
        line-height:26px;
        vertical-align:middle;
    }

    &-warning{
        font-size: 12px;
        color: #BABABE;
        position: absolute;
        z-index: 50;
        top: -28px;
        right: 24px;
        padding: 1px 12px;
        white-space: nowrap;
        opacity: 0;
        transition: all 0.2s;
        background-color: #F5F7FA;
        border-radius: 4px 0 0 4px;
        display: none;
    }

    &-actions {
        //display:block;
        display: none;
        position:absolute;
        top:-28px;
        right:-1px;
        height:28px;
        padding:0 3px;
        line-height:28px;
        z-index:100;

        background:darken($color-fill, 1%);
        border:1px darken($color-fill, 1%) solid;
        border-radius:4px 4px 0 0;
        border-bottom:0;

        opacity:0;
        transition:all 0.2s;

        a {
            color:$color-success;
            padding:0 3px;
            font-size:12px;
            transition:all 0.2s;

            &.-save {
                display:none;
            }
            &.-remove {
                color: $color-danger;
            }

            &:hover, &:focus {
                opacity:0.7;
                text-decoration:none;
            }
        }
    }

    &-counter{
        display: none;
        position: absolute;
        top: -29px;
        left: 0;
        font-size: 14px;
        color: #959BAB;
        letter-spacing: 0;
        font-family: $font-family;

        opacity:0;
        transition:all 0.2s;

        &-left{
            &.-warning{
                color: $color-error;
            }
        }

        &.-secondary{
            top: auto;
            left: 5px;
            bottom: -29px;
        }
    }

    &.-textarea {
        margin-left:-8px;
        background:none;
        border-color:transparent;
        text-align:left;

        &.transcription {
            width: 150px;
            border: 1px solid #ccc;
        }
    }

    &-transcription {
        &-label {
            padding: 4px 0;
            font-size: 12px;
            color: #ccc;
        }
    }


    &.-empty &-input {
        color:$color-disabled;
    }

    &:hover {
        background:darken($color-fill, 1%);
        border-radius:4px 0 4px 4px;


    }

    &:hover &-actions {
        display: block;
        opacity:1;
    }

    &:hover &-counter {
        display: block;
        opacity:1;
    }

    &.-edit &-counter{
        display: block;
        opacity:1;
    }

    &:hover &-warning {
        display: block;
        opacity:1;
    }

    &.-edit {
        cursor:text;
        background:#fff;
        border-color:$color-success;
        border-radius:4px 0 4px 4px;

        a {
            &.-save {
                display:inline;
            }

            &.-edit {
                display:none;
            }
        }
    }

    &.-edit &-actions {
        opacity:1;
        background:#fff;
        border-color:$color-success;
        display: block;
    }

    &.-existing{
        color: #BABABE;
        background-color: #F9FBFC;
        border-color: #F9FBFC;
    }

    &.-existing &-actions{
        background-color: #F5F7FA;
        border-color: #F5F7FA;
        border-radius: 0 4px 0 0;
    }
}
