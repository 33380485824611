$table-header-border: #1565C0;
$table-header: #1976D2;
$table-border: #d9d9d9;

.properties-page {
  padding-bottom: 0;

  .search-container {
    border-top: solid 3px white;
    position: fixed;

    width: 100%;
    height: 40px;

    .ewa-icon {
      &.-search {
        position: absolute;
        top: 8px;
        left: 30px;

        font-size: 20px;
      }
    }

    input {
      border: 0;
      width: 100%;
      height: 100%;


      font-size: 16px;
      font-weight: bold;

      background: #EBEFF5;

      line-height: 40px;
      padding-left: 60px;
    }

    .ewa-form-group {
      margin: 0;

      position: absolute;
      right: 6%;
      bottom: 11px;
    }
  }
  .properties-container {
    margin-top: 30px;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 10px 20px;

    .property {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 15px;
      background-color: transparentize(#BDBDBD, .8);
      width: 100%;
      box-shadow: 0 1px 2px rgba(25,25,25,0.2);

      &-main {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;

        &.deleted {
          opacity: 0.5;
          pointer-events: none;
        }

        &-heading {
          display: flex;
          flex-flow: row nowrap;
          height: 35px;
          border-bottom: 1px solid transparentize(#455A64, .9);
          justify-content: space-between;
          align-items: center;
          padding: 0 10px 0 0;

          &-name {
            flex: 10;
            input {
              font-weight: bold;
              font-size: 16px;

              height: 30px;
              background-color: transparent;
              width: 100%;
              outline: none;
              border: none;
              background-color: transparentize(#fff, .7);
              transition: all .3s ease-out;
              padding-left: 11px;

              &:focus {
                background-color: transparentize(#fff, .2);
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 .8px transparentize(#455A64, .9);
              }
            }
          }

          &-platforms {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            max-width: 120px;
            flex: 1;

            &-selector {
              cursor: pointer;
              font-size: 15px;
              height: 25px;
              width: 25px;
              text-align: center;
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;

              &.ios {
                color: #212121;
              }

              &.android {
                color: #A4C639;
              }

              &.web {
                color: #E64A19;
              }

              &:hover {
                color: #fff;
                background-color: transparent;
              }

              &.active,
              &.web.active,
              &.globe.active,
              &.android.active {
                background-color: #fff;
                color: transparentize(#212121, .1);
                pointer-events: none;
              }
            }
          }
        }

        &-translations {
          padding: 10px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: flex-start;

          &-heading {
            display: flex;
            flex-flow: row nowrap;
            height: 22px;

            &-item {
              &:first-child {
                min-width: 150px;
              }

              margin-right: 7px;
              min-width: 180px;
              font-weight: bold;
              color: transparentize(#212121, .3);
              font-size: 13px;
              padding: 0px 6px;
            }
          }

          &-item {
            display: flex;
            width: 100%;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            height: 18px;
            margin-bottom: 1px;

            &-name {
              display: flex;
              flex-flow: row nowrap;
              flex: 2;
              min-width: 150px;
              background-color: transparentize(#212121, .3);
              color: transparentize(#CFD8DC, .05);
              font-weight: bold;
              padding: 2px 6px;
              border-radius: 3px;
              margin-right: 7px;
            }

            &-value {
              display: flex;
              flex-flow: row nowrap;
              width: 100%;

              span {
                display: inline-block;
                width: 100%;

                input {
                  padding-left: 10px;
                  width: 100%;
                  background-color: transparentize(#fff, .3);
                  border: none;
                  outline: none;
                  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 .3px rgba(102,175,233,.6);

                  &:focus {
                    background-color: transparentize(#fff, .8);
                  }

                  &.empty {
                    &::placeholder {
                      color: #E64A19;
                    }
                  }
                }
              }

              ol {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                width: 80%;
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                  margin: 0 4px;
                  display: flex;
                  flex-flow: row nowrap;

                  strong {
                    background-color: transparentize(#455A64, .9);
                    border-radius: 3px;
                    padding: 2px 6px;
                    margin-right: 7px;
                  }

                  span {
                    input {
                      min-width: 180px;
                    }
                  }
                }
              }
            }

            &:hover {
              .property-main-translations-item-name {
                color: #fff;
              }
            }
          }
        }
      }

      &-control {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: space-around;
        width: 50px;
        color: #212121;
        background-color: transparentize(#455A64, .9);

        &-group {
          display: flex;
          flex-flow: column nowrap;
          flex: 1;
          justify-content: center;
          align-items: center;

          &:first-child {
            flex: 1;
            max-height: 76px;
          }

          &:last-child {
            flex: 1;
          }

          &-item {
            display: flex-flow;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 50px;
            cursor: pointer;
            font-size: 18px;
            padding: 15px;

            &:hover {
              color: #fff;
              background-color: transparentize(#455A64, .9);
            }

            &.activation {
              font-size: 16px;
              text-align: center;
              align-items: center;
              height: 35px;
              padding: 12px 0;
              box-sizing: border-box;

              &:hover {
                color: #212121;
                background-color: transparent;
              }

              &.active {
                color: $color-success;
              }
            }

            &.disabled {
              opacity: .4;
              pointer-events: none;

              &:hover {
                color: #212121;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.localized-form {
  margin: 2rem 0;
  thead {
    tr {
      th {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 1rem;
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        font-size: 16px;
        font-weight: bold;
        padding-right: 1rem;
      }
      td {
        input {
          width: 100%;
          padding: 1rem 0 1rem 1rem;
        }
      }
    }
  }
}

.properties-table {
  grid-template-columns:
    minmax(15rem, 2.63fr)
    minmax(60rem, 5.67fr)
    minmax(10rem, 1.27fr)
    minmax(10rem, 1.0fr)
    minmax(10rem, 0.33fr);

  .value {
    display: flex;
    flex-flow: column nowrap;

    .input {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 0.2rem;

      .label, .input-body {
        color: inherit;
        text-align: left;
        min-width: 10rem;
        align-items: center;
      }

      .label {
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        display: flex;
      }

      .input-body {
        width: 100%;

        &.valid {
          border-right: .3rem solid darken(green, .5);
          padding-right: .2rem;
        }

        input {
          padding: .5rem .2rem;
        }
      }
    }
  }
}

.tags {
  .tag {
    position: relative;
  }
}

#edit-tags {
  .fas {
    &.fa-plus {
      display: block;
      text-align: center;
      padding: 25px 20px;
      cursor: pointer;
    }
    &.fa-trash-alt {
      position: absolute;
      right: 0;
      top: 20px;
    }
  }
}
