.breadcrumbs {
    .breadcrumb {
        &-item {
            font-size: 18px;
            margin-right: 10px;
        }
        .ewa-icon {
            font-weight: bold;
            margin-right: 10px;
            &::before {
                position: relative;
                top: 1px;
            }
        }
        &:last-child {
            .ewa-icon {
                display: none;
            }
        }
    }
}