.badge-lang {
    display: inline-block;
    margin: 2px;
    padding: 5px 3px;
    color: white;
    border-radius: 3px;
    background: #ccc;
    cursor: pointer;

    &:hover {
      background: lightgreen;
    }
  
    &.-success {
      background: lightgreen;
    }

    &.-loading {
      outline: solid 2.5px #1f43bd;
    }
  
    &.-denied {
      background: red;
    }
  }
  
  #modal-build-dictor {
    .fa-check {
      color: lightgreen;
    }
  
    .title {
      text-align: center;
  
      font-size: 30px;
  
      padding: 30px 0;
      border-bottom: solid 1px #ccc;
    }

    .ewa-modal-header {
      .translate-select {
        background-color: #fff;
        background-image: none;
        border: none;
        font-size: 15px;
        border-bottom: 1px solid hsla(220, 5%, 87%, .3);
        box-shadow: none;
        height: 34px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        width: 80%;
  
        margin-right: 30px;
      }
    }
  }

  #modal-material-edit {
    .fa-check {
      color: lightgreen;
    }
  
    .title {
      text-align: center;
  
      font-size: 30px;
  
      padding: 30px 0;
      border-bottom: solid 1px #ccc;
    }

    .ewa-modal-header {
      .translate-select {
        background-color: #fff;
        background-image: none;
        border: none;
        font-size: 15px;
        border-bottom: 1px solid hsla(220, 5%, 87%, .3);
        box-shadow: none;
        height: 34px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        width: 80%;
  
        margin-right: 30px;
      }
    }
  }
  
  
