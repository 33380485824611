.price-tag {
  background: #058;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  height: 30px;
  line-height: 30px;
  margin-right: 1rem;
  padding: 0 0.666rem;
  position: relative;
  text-align: center;
}
.price-tag:hover {
  background: #07b;
}
