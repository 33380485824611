.ewa-thumbnail {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 34px;
    margin: 0 15px 0 0;
    overflow: hidden;

    background-color: $color-fill;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 3px;

    color:$color-smooth;
    font-size:13px;
    font-weight:800;
    line-height: 34px;
    vertical-align: middle;
    text-align:center;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.-selectable {
        cursor:pointer;

        &:before {
            display:block;
            content: "";
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;

            background:rgba(255,255,255,0.2);
            opacity:0;

            transition:background 0.2s, opacity 0.2s, color 0.2s;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &:active {
            &:before {
                opacity: 0.9;
            }
        }
    }

    &.-editable {
        cursor:pointer;

        &:before {
            @include icon-styles;
            content: icon-char(camera);
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;

            color:rgba(255,255,255,0.6);
            background:rgba(0,0,0,0.6);
            opacity:0;

            font-size:23px;
            font-weight:normal;
            text-align:center;

            transition:background 0.2s, opacity 0.2s, color 0.2s;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &:active {
            &:before {
                opacity: 0.9;
            }
        }

        &.-empty {
            &:before {
                background:darken($color-fill,3.53%);
                color:#fff;
                opacity:1;
            }

            &:hover {
                &:before {
                    background:darken($color-fill,6%);
                }
            }
            &:active {
                &:before {
                    background:darken($color-fill,7%);
                }
            }

        }
    }

    &.-loading {
        @extend .ewa-loader_basic;

        cursor:wait;
        background:$color-fill !important;
        color:$color-fill !important;

        &:before {
            background:none;
            opacity:1;
        }
    }

    &.-sm {
        height:34px;
        width:34px;

        line-height:35px;

        &.-loading {
            &:after {
                margin: -5px;
                height: 10px;
                width: 10px;
            }
            &:before {
                margin: -10px;
                height: 20px;
                width: 20px;
            }
        }
    }

    &.-md{
        height:54px;
        width:54px;

        line-height:54px;

        margin: 0 18px 0 0;
    }

    &.-lg {
        height:70px;
        width:70px;

        line-height:70px;
    }

    &.-xl {
        margin: 0 25px 0 0;
        height:85px;
        width:85px;

        line-height:85px;
    }

    &.-xxl {
        width: 213px;
        height: 105px;
    }

    &.-disabled{
        cursor: default !important;
        pointer-events: none !important;
    }

}
