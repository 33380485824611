.suggestions {
    background: #fff;
    z-index: 2;
    width: 100%;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;

    .suggestion {
        z-index: 3;
        cursor: pointer;
        padding: 10px 20px;
        color: $color-primary;
        font-size: 12px;

        &:hover {
            background: $color-bg;
        }
    }
}

.backdrop {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
