/* Typeahead Override */
.twitter-typeahead {
    display: block !important;

    .tt-menu {
        @extend .ewa-dropdown-menu;
        margin: -7px 0 0 0 !important;
        max-height: 130px;
        overflow: auto;

        .tt-suggestion {
            cursor: pointer;
            padding: 8px;

            border-bottom: 1px $color-fill solid;

            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            letter-spacing: 0.3px;
            text-transform: none;

            &:hover, &.tt-cursor {
                background: darken($color-bg, 1%);
                color: $color-secondary;
            }
        }
    }
}

.ewa-typeahead .twitter-typeahead .tt-menu {
    width: 100%;
    overflow: hidden;
    max-height: none;

    .tt-suggestion {
        margin: 0;
    }
}