.ewa-btn {
    &-group {
        margin:0 -10px;

        a, button {
            margin:0 10px;
        }
    }

    &_primary {
        position:relative;
        display:inline-block;
        height: 4.5rem;
        padding: 0 3.5rem;
        z-index:0;
        overflow:hidden;

        cursor:pointer;
        color:#fff;
        background:$color-secondary;
        border:0;
        border-radius:3px;
        outline:none;

        font-size:16px;
        font-weight:normal;
        letter-spacing:0.04px;
        line-height:46px;
        vertical-align:middle;
        text-align:center;
        transition: all 0.2s;

        i {
            display:inline-block;
            font-size: 2.5rem;
        }

        span {
            display:inline-block;
            transition:all 0.1s;
        }

        &:hover, &:focus {
            background:lighten($color-secondary, 10%);
            color:#fff;
            outline:none;
            text-decoration:none;
        }

        &:active {
            opacity:0.9;
        }

        &:disabled {
            cursor:default;
            background:rgba(174,184,198,0.2);
            opacity:0.4;
        }

        &.-full {
            width:100%;
            max-width: 100%;
        }

        &.-lg {
            padding:0 13px;
            min-width: 55px;
            height: 50px;

            border-radius:4px;
            font-weight:600;
            letter-spacing:0.5px;
            line-height:50px;
        }

        &.margin {
            margin-left: 5px;
            margin-right: 5px;
        }

        &.-loading {
            background: $color-secondary;
            opacity:1;

            span {
                opacity:0;
            }

            &:after, &:before {
                content:"";
                display:block;
                position:absolute;
                top:50%;
                left:50%;
                transform:scale(1);

                border-radius:100%;
                border:1px #fff solid;

                animation-duration:1s;
                animation-iteration-count:infinite;
            }

            &:after {
                margin:-10px;
                height:20px;
                width:20px;

                border-color:rgba(255,255,255,0.8);
                opacity:0;

                animation-name:ewa-btn-loading-1;
                animation-delay:0.3s;
            }

            &:before {
                margin:-18px;
                height:36px;
                width:36px;

                border-color:rgba(255,255,255,0.5);
                animation-name:ewa-btn-loading-2;
            }
        }
    }

    &_secondary {
        @extend .ewa-btn_primary;

        background:$color-primary;
        color:#fff;

        &:hover, &:focus {
            background:lighten($color-primary, 10%);
        }

        &:disabled {
            cursor:default;
            background:lighten($color-primary, 15%);
            opacity:1;
        }

        &.-loading {
            background:$color-primary;
        }

        &.-gold {
             background:rgba($color-gold, 0.8);

            &:hover, &:focus {
                background:rgba($color-gold, 0.9);
            }

            &:active {
                background:rgba($color-gold, 0.9);
            }
        }

        &.-column {
            margin-top: 5px;
        }

        &.-success {
            background: darken($color-success, 25%);

             &:hover, &:focus {
                background:lighten($color-success, 10%);
            }
        }
    }

    &_default {
        @extend .ewa-btn_primary;

        background:$color-fill;
        color:$color-text;
        font-weight:500;

        &:hover, &:focus {
            background:darken($color-fill, 5%);
            color:$color-text;
        }

        &.-loading {
            background:$color-fill;
        }

        &.-primary {
            color:$color-primary;

            &:hover, &:focus {
                color:$color-primary;
            }
        }
    }

    &_search {
        display:inline-block;
        margin:0 25px 0 0;
        padding:0;

        background:none;
        border:0;
        outline:none;
        color:$color-primary;
        font-size:21px;
        vertical-align:middle;

        transition:all 0.15s;

        i {
            position:relative;
            top:2px;
        }

        &:hover, &:focus {
            color:lighten($color-primary, 20%);
            text-decoration:none;
            outline:none;
        }
    }

    &_round {
        position:relative;
        display:inline-block;
        height:34px;
        width:34px;
        margin:0 4px;

        background:$color-muted;
        border:0;
        border-radius:100%;
        outline:0;

        color:#A9AFBC;
        line-height:1;
        vertical-align:middle;

        transition:all 0.2s;

        i {
            display:inline-block;
            margin:2px 0 0 0;
            font-size:16px;

            &.-times{
                font-size: 8px;
            }
        }

        &:disabled {
            background:$color-muted !important;
            opacity:0.2;
        }

        &:hover, &:focus {
            background:darken($color-muted, 5%);
            outline:none;
            text-decoration:none;
        }

        &:active, .active {
            background:darken($color-muted, 8%);
        }

        &.-info {
            background:$color-info;
            color:$color-secondary;

            &:hover, &:focus {
                background:darken($color-info, 5%);
                outline:none;
            }

            &:active {
                background:darken($color-info, 10%);
            }
        }

        &.-danger {
            background:rgba($color-danger, 0.1);
            color:$color-danger;

            &:hover, &:focus {
                background:$color-danger;
                color:#fff;
                outline:none;
            }

            &:active {
                background:rgba($color-danger, 0.7);
                color:#fff;
            }
        }

        &.-grey {
            background-color: rgba($color-grey, 0.2);

            &:hover, &:focus {
                background-color: rgba($color-grey, 0.4);
            }

            &:active {
                background:rgba($color-gold, 0.4);
                color:#fff;
            }

            span {
                font-size: 16px;
                color: $color-smooth;
                transition: all 0.2s;
            }
        }

        &.-gold {
            background:rgba($color-gold, 0.1);

            &:hover, &:focus {
                background:rgba($color-gold, 0.2);
            }

            &:active, .active {
                background:rgba($color-gold, 0.2);
            }

            span {
                font-size: 16px;
                color: $color-gold;
                transition: all 0.2s;
            }
        }

        &.-error {
            background:$color-error;
            color:#fff;

            &:hover, &:focus {
                background:lighten($color-error, 3%);
                outline:none;
            }

            &:active {
                background:lighten($color-error, 5%);
            }
        }

        &.-sm {
            height:26px;
            width:26px;

            i {
                margin:1px 0 0 0;
                font-size:12px;
            }
        }

        &.-loading {
            background:$color-muted !important;

            i {
                opacity:0;
            }

            &:after, &:before {
                content:"";
                display:block;
                position:absolute;
                top:50%;
                left:50%;
                transform:scale(1);

                border-radius:100%;
                border:1px #A9AFBC solid;

                animation-duration:1s;
                animation-iteration-count:infinite;
            }

            &:after {
                margin:-5px;
                height:10px;
                width:10px;

                border-color:rgba(0,0,0,0.3);
                opacity:0;

                animation-name:ewa-btn-loading-1;
                animation-delay:0.3s;
            }

            &:before {
                margin:-9px;
                height:18px;
                width:18px;

                border-color:rgba(0,0,0,0.1);
                animation-name:ewa-btn-loading-2;
            }
        }

        &.-success {
            background:rgba(green, 0.2);
            color:green;

            &:hover, &:focus {
                background:green;
                color:#fff;
                outline:none;
            }

            &:active {
                background:rgba(green, 0.7);
                color:#fff;
            }
        }
    }

    &_rounded {
        position:relative;
        display:inline-block;
        height:34px;
        margin:0 4px;
        padding:6px 16px;

        background:$color-success;
        border:0;
        border-radius:20px;
        outline:0;

        color:#fff;
        font-size:12px;
        letter-spacing:0.42px;
        line-height:1;
        vertical-align:middle;

        transition:background 0.2s;

        i {
            margin:0 10px 0 -2px;
            font-size:8px;
        }

        &:hover, &:focus {
            background:lighten($color-success, 3%);
            outline:none;
        }

        &:active, .active {
            background:lighten($color-success, 5%);
        }

        &.-sm {
            height:26px;
        }

        &.-lg {
            height:50px;
            padding:6px 50px;

            border-radius:40px;
            font-size:16px;
            font-weight:600;
            letter-spacing:0.5px;
        }

        &.-danger {
            background:rgba($color-danger, 0.1);
            color:$color-danger;

            &:hover, &:focus {
                background:$color-danger;
                color:#fff;
                outline:none;
            }

            &:active {
                background:rgba($color-danger, 0.7);
                color:#fff;
            }
        }

        &.-gold {
            background: rgba($color-gold, 0.1);
            color: $color-gold;

            &:hover, &:focus {
                background: $color-gold;
                color: #fff;
                outline: none;
            }

            &:active {
                background: rgba($color-gold, 0.7);
                color: #fff;
            }
        }

        &.-success {
            background:rgba($color-success, 0.1);
            color:$color-success;

            &:hover, &:focus {
                background:rgba($color-success, 0.2);
                outline:none;
            }

            &:active {
                background:rgba($color-success, 0.25);
            }
        }

        &.-grey {
            background:rgba($color-grey, 0.6);
            color:rgba($color-text, 0.6);

            &:hover, &:focus {
                background:rgba($color-grey, 0.9);
                outline:none;
            }

            &:active {
                background:rgba($color-grey, 0.9);
            }
        }

        &.-blue {
            background:rgba($color-blue, 0.3);
            color:rgba($color-text, 0.8);

            &:hover, &:focus {
                background:rgba($color-blue, 0.6);
                outline:none;
            }

            &:active {
                background:rgba($color-blue, 0.6);
            }
        }

        &.-restore {
            background:$color-fill;
            color:$color-primary;
            font-weight:500;

            &:hover, &:focus {
                background:darken($color-fill, 5%);
                outline:none;
            }

            &:active {
                background:darken($color-fill, 8%);
            }
        }

        &.-loading {
            background:rgba($color-success, 0.2) !important;

            i, span {
                opacity:0;
            }

            &:after, &:before {
                content:"";
                display:block;
                position:absolute;
                top:50%;
                left:50%;
                transform:scale(1);

                border-radius:100%;
                border:1px #fff solid;

                animation-duration:1s;
                animation-iteration-count:infinite;
            }

            &:after {
                margin:-5px;
                height:10px;
                width:10px;

                border-color:rgba($color-success,0.6);
                opacity:0;

                animation-name:ewa-btn-loading-1;
                animation-delay:0.3s;
            }

            &:before {
                margin:-9px;
                height:18px;
                width:18px;

                border-color:rgba($color-success,0.5);
                animation-name:ewa-btn-loading-2;
            }
        }
    }

    &_word {
        display:inline-block;
        height:28px;
        margin:4px 0;
        padding:0 9px;
        line-height:28px;
        vertical-align:middle;
        text-align:center;

        background:$color-fill;
        border:0;
        border-radius:4px;

        color:$color-smooth;
        font-size:10px;

        &:hover, &:focus {
            background:darken($color-fill, 1%);
        }

        &:active {
            background:darken($color-fill, 2%);
        }
    }
}

@keyframes ewa-btn-loading-1 {
    0% {
        opacity:1;
        transform:scale(0);
    }

    50% {
        opacity:1;
    }

    100% {
        opacity:0;
        transform:scale(2);
    }
}

@keyframes ewa-btn-loading-2 {
    0% {
        opacity:1;
        transform:scale(0);
    }

    50% {
        opacity:1;
    }

    100% {
        opacity:0;
        transform:scale(1.2);
    }
}


@media (max-width: 1050px) {
    .ewa-btn {
        &_secondary, &_primary, &_default, .-lg {
            display:inline-block;
            padding: 0.35rem 1.2rem;
            border: 0.1rem solid #FFFFFF;
            margin: 0.4rem auto;
            border-radius:0.12em;
            box-sizing: border-box;
            text-decoration:none;
            font-weight: 300;
            color:#FFFFFF;
            text-align:center;
            transition: all 0.2s;
            width: 45%;
            line-height: 1;
            height: auto;
            min-width: 45%;
            max-width: 45%;
            text-align: center;


            span {
                margin: 0;
                padding: 5px 3px;
                height: auto;
                line-height: 1;
            }

            .ewa-icon {
                max-height: 50px;
                line-height: 1;
            }
        }
    }
}
