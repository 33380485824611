.ewa-topbar {
    background:$color-fill;
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:99;

    font-size:16px;

    &-outer {
        position:relative;
        height:100%;
        padding-top:66px;

        &.scrolled {
            overflow-y: scroll;
        }

        &.triple-padded {
          padding-top:197px;
        }
    }

    &-container {
        padding:0 25px;
    }

    &-nav {
        display:inline-block;
        float:left;
        margin:0 -15px;
        padding-right: 30px;

        vertical-align:middle;
        line-height:66px;

        &-item {
            display:inline-block;
            margin:0 15px;

            cursor:pointer;
            color:$color-smooth;
            font-size:16px;
            font-weight:600;
            letter-spacing:1.09px;
            vertical-align:middle;

            transition:all 0.15s;

            .languages {
                position: absolute;
                top: 0;

                a {
                    margin: 0 2px;
                }
            }

            &-counter {
                padding-left:8px;

                &:before, &:after {
                    display:inline-block;
                    padding:0 1px;
                }

                &:before {
                    content: "[";
                }

                &:after {
                    content: "]";
                }
            }

            &:hover, &:focus {
                color:darken($color-smooth, 15%);
                text-decoration:none;
            }

            &.-active {
                cursor:default;
                color:$color-primary;
            }
        }
    }

    &-content {
        display:inline-block;
        float:left;

        vertical-align:middle;
        line-height:66px;
    }

    &-search {
        position:absolute;
        top:0;
        left:0;
        top:-66px;
        display:table;
        height:66px;
        width:100%;

        background:$color-fill;
        opacity:0;

        transition:all 0.3s;

        &-icon {
            display:table-cell;
            padding:0 45px 0 25px;
            width:65px;
            font-size:21px;
            vertical-align:middle;
        }

        &-form {
            display:table-cell;
            vertical-align:middle;

            input {
                width:100%;

                background:none;
                border:0;
                outline:0;
                opacity:0.5;

                color:$color-primary;
                font-size:18px;
                font-weight:normal;
                letter-spacing:0.71px;

                transition:opacity 0.15s;

                &::-webkit-input-placeholder {color:$color-primary;}
                &::-moz-placeholder          {color:$color-primary;}
                &:-moz-placeholder           {color:$color-primary;}
                &:-ms-input-placeholder      {color:$color-primary;}

                &:hover {
                    opacity:0.7;
                }

                &:focus {
                    opacity:1;
                }
            }
        }

        &-close {
            display:table-cell;
            width:80px;

            color:$color-primary;
            font-size:16px;
            text-align:center;
            vertical-align:middle;

            transition:all 0.15s;

            &:hover, &:focus {
                color:lighten($color-primary, 20%);
                text-decoration:none;
            }
        }

        &.-visible {
            top:0;
            opacity:1;
        }
    }

    &-actions {
        display:inline-block;
        float:right;
        line-height:60px;
        vertical-align:middle;

        .ewa-btn_primary {
            margin-right: 5px;
        }

        > span {
            display:inline-block;
            margin:2px 25px 0 0;
            color:$color-disabled;
        }

        &.-settings-bar {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &.-nobg {
        background:none;
    }
}
