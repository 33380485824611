.ewa-dropdown {
    display:inline;
    position:relative;

    &-toggle {
        i {
            transition:all 0.2s;
        }
    }

    &-menu {
        display:none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 110px;
        margin:4px 0 0 0;
        padding:0;
        float: left;
        z-index: 1000;

        background: #fff;
        border: 1px rgba(143, 150, 166, 0.6) solid;
        border-radius: 4px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

        list-style: none;

        > li {
            padding:8px;

            border-bottom:1px $color-fill solid;

            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            letter-spacing: 0.3px;
            text-transform: none;

            &.-checkbox {
                position:relative;

                label {
                    cursor:pointer;
                    height:100%;
                    width:100%;
                    padding:0;
                    margin:0;
                    font-style:normal;
                    font-weight:normal;

                    input {
                        position:absolute;
                        z-index:-999;
                        opacity:0;
                        visibility:hidden;
                    }

                    span {
                        &:after {
                            @include icon-styles;
                            content:icon-char(check);
                            display:block;
                            height:12px;
                            width:12px;
                            float:right;

                            background:$color-primary;
                            border-radius:100%;
                            opacity:0;

                            color:#fff;
                            font-size:6px;
                            line-height:12px;
                            text-align:center;
                            vertical-align: middle;

                            transition:all 0.2s;
                        }
                    }

                    input:checked + span {
                        color: $color-text;

                        &:after {
                            opacity:1;
                        }
                    }

                    .lang {
                        i {
                            margin-right:2px;
                        }
                    }
                }
            }

            &:last-child {
                border-bottom:none;
            }

            &:hover {
                background:darken($color-bg, 1%);
            }
        }
    }

    &.open &-menu {
        display:block;
        animation: ewa-dropdown-show 0.2s;
    }

    &.open &-toggle {
        i {
            transform:rotate(180deg);
        }
    }

    &.-secondary{
        vertical-align: middle;
    }

    &.-secondary &-toggle{
        color: #181A25;
        font-size: 16px;

        i {
            fill: #8F96A6;
            opacity: 0.5;
            margin-left: 7px;
        }
    }

    &.-secondary &-menu{
        & > li.-checkbox{
            label{
                span{
                    margin-right: 5px;
                }
            }
        }
    }
}

@keyframes ewa-dropdown-show {
    0% {
        opacity:0;
        transform:translateY(-10px);
    }

    100% {
        opacity:1;
        transform:translateY(0);
    }
}
