.ewa-content#onboarding {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 8rem 20rem;

  textarea {
  	border: solid 0.0625rem #f3f3f3;
  	border-radius: 1rem;

  	box-shadow: 0 5px 25px black;

  	padding: 2rem;
  	font-size: 18px;

		width: 100%;
		height: 100%;

		resize: none;
  }

  #jsoneditor {
    height: 100%;
  }

  .age-range-button {
    cursor: pointer;
    margin: 1rem;

    &.active {
      background: lighten($color-success, 40%);
      pointer-events: none;
    }
  }
}
