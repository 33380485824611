.manual-edit {
  margin: 20px 0 20px 80px;
  font-size: 24px;

  .sources {
    padding: 40px 80px;

    .source {
      &:hover {
        font-weight: bold;
      }

      &.active {
        font-weight: normal;
        background: #ccc;
      }

      position: relative;
      padding: 10px 15px;
      margin: 10px 0;
      cursor: pointer;

      .source-options {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 50px;
        background: black;
        height: 100%;

        color: white;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .sentence {
        display: flex;
        margin-bottom: 30px;

        .origin {
          min-width: 400px;

          textarea {
            width: 100%;
            height: 100%;
            min-height: 150px;
          }
        }

        .translation {
          min-width: 400px;

          textarea {
            width: 100%;
            height: 100%;
            min-height: 150px;
          }
        }

        .sentence-is-verified {
          font-size: 14px;
          margin-top: 3px;
        }

        .sentence-is-chapter-header {
          font-size: 14px;
          margin-top: 3px;
        }
      }

      .word {
        display: flex;
        .word-pos {
          font-size: inherit;
        }
        .word-origin {
          font-size: inherit;
        }
        .word-lemma {
          font-size: inherit;
        }
        .word-translation {
          max-width: 100%;

          select {
            max-width: 90%;
          }

          *.warning {
            background-color: yellow;
          }
          *.info {
            background-color: lightblue;
          }
          *.info-plum {
            background-color: plum;
          }
          font-size: 22px;
        }
        .word-is-verified {
          font-size: 14px;
          margin-top: 5px;
        }
        .word-preserve-lemma {
          font-size: 14px;
          margin-top: 5px;
        }
      }
      .source-loading-indicator {
        &.-loading {
          border: 6px solid #f3f3f3;
          border-top: 6px solid #3498db;
          border-radius: 50%;
          width: 28px;
          height: 28px;
          animation: spin 2s linear infinite;
        }
      }
    }
  }
}
