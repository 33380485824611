.ewa-sprite {
    display:inline-block;
    background-repeat: no-repeat;

    &.-flag-be, &.-flag-en, &.-flag-ga, &.-flag-hr, &.-flag-hu,
    &.-flag-mn, &.-flag-ms, &.-flag-sl, &.-flag-tg, &.-flag-tl,
    &.-flag-fa, &.-flag-bg, &.-flag-de, &.-flag-fi, &.-flag-fil,
    &.-flag-lb, &.-flag-sco, &.-flag-bo, &.-flag-et, &.-flag-lt,
    &.-flag-lv, &.-flag-pl, &.-flag-sv, &.-flag-af, &.-flag-ar,
    &.-flag-cs, &.-flag-el, &.-flag-eo, &.-flag-fr,
    &.-flag-gl, &.-flag-hi, &.-flag-id, &.-flag-it, &.-flag-ja,
    &.-flag-km, &.-flag-ko, &.-flag-nl, &.-flag-ro,
    &.-flag-sk, &.-flag-so, &.-flag-sr,
    &.-flag-uk, &.-flag-vi, &.-flag-ca, &.-flag-fo, &.-flag-he,
    &.-flag-is, &.-flag-br, &.-flag-sq, &.-flag-da, &.-flag-eu,
    &.-flag-no, &.-flag-nn, &.-flag-se, &.-flag-th {
        display: inline-block;
        background: url('../assets/img/sprites/flags.png') no-repeat;
        overflow: hidden;
        text-indent: -9999px;
        border-radius: 0;
    }

    &.-flag-az {
        background: url('../assets/img/flags/az.png') no-repeat;
        background-size: cover;
        background-position: -3px;
    }

    &.-flag-kk {
        background: url('../assets/img/flags/kk.png') no-repeat;
        background-size: cover;
    }

    &.-flag-hy {
        background: url('../assets/img/flags/hy.png') no-repeat;
        background-size: cover;
    }

    &.-flag-ka {
        background: url('../assets/img/flags/ka.png') no-repeat;
        background-size: cover;
    }

    &.-flag-wa {
      background: url('../assets/img/flags/wa.jpg') no-repeat center center;
      background-size: 125%;
    }

    &.-flag-zh_hant {
      background: url('../assets/img/flags/zh_hant.png') no-repeat;
      background-size: cover;
    }

    &.-flag-be { background-position: -0px -0px; width: 24px; height: 12px; }
    &.-flag-en { background-position: -0px -12px; width: 24px; height: 12px; }
    &.-flag-ga { background-position: -0px -24px; width: 24px; height: 12px; }
    &.-flag-hr { background-position: -0px -36px; width: 24px; height: 12px; }
    &.-flag-hu { background-position: -0px -48px; width: 24px; height: 12px; }
    &.-flag-mn { background-position: -0px -60px; width: 24px; height: 12px; }
    &.-flag-ms { background-position: -0px -72px; width: 24px; height: 12px; }
    &.-flag-sl { background-position: -0px -84px; width: 24px; height: 12px; }
    &.-flag-tg { background-position: -0px -96px; width: 24px; height: 12px; }
    &.-flag-tl { background-position: -0px -108px; width: 24px; height: 12px; }
    &.-flag-fa { background-position: -0px -120px; width: 21px; height: 12px; }
    &.-flag-bg { background-position: -0px -132px; width: 20px; height: 12px; }
    &.-flag-de { background-position: -0px -144px; width: 20px; height: 12px; }
    &.-flag-fi { background-position: -0px -156px; width: 20px; height: 12px; }
    &.-flag-fil { background-position: -0px -168px; width: 20px; height: 12px; }
    &.-flag-lb { background-position: -0px -180px; width: 20px; height: 12px; }
    &.-flag-sco { background-position: -0px -192px; width: 20px; height: 12px; }
    &.-flag-bo { background-position: -0px -204px; width: 19px; height: 12px; }
    &.-flag-et { background-position: -0px -216px; width: 19px; height: 12px; }
    &.-flag-lt { background-position: -0px -228px; width: 19px; height: 12px; }
    &.-flag-lv { background-position: -0px -240px; width: 19px; height: 12px; }
    &.-flag-pl { background-position: -0px -252px; width: 19px; height: 12px; }
    &.-flag-sv { background-position: -0px -264px; width: 19px; height: 12px; }
    &.-flag-af { background-position: -0px -276px; width: 18px; height: 12px; }
    &.-flag-ar { background-position: -0px -288px; width: 18px; height: 12px; }
    &.-flag-cs { background-position: -0px -300px; width: 18px; height: 12px; }
    &.-flag-el { background-position: -0px -312px; width: 18px; height: 12px; }
    &.-flag-eo { background-position: -0px -324px; width: 18px; height: 12px; }
    &.-flag-fr { background-position: -0px -348px; width: 18px; height: 12px; }
    &.-flag-gl { background-position: -0px -360px; width: 18px; height: 12px; }
    &.-flag-hi { background-position: -0px -372px; width: 18px; height: 12px; }
    &.-flag-id { background-position: -0px -384px; width: 18px; height: 12px; }
    &.-flag-it { background-position: -0px -396px; width: 18px; height: 12px; }
    &.-flag-ja { background-position: -0px -408px; width: 18px; height: 12px; }
    &.-flag-km { background-position: -0px -420px; width: 18px; height: 12px; }
    &.-flag-ko { background-position: -0px -432px; width: 18px; height: 12px; }
    &.-flag-nl { background-position: -0px -444px; width: 18px; height: 12px; }
    &.-flag-ro { background-position: -0px -468px; width: 18px; height: 12px; }
    &.-flag-sk { background-position: -0px -492px; width: 18px; height: 12px; }
    &.-flag-so { background-position: -0px -504px; width: 18px; height: 12px; }
    &.-flag-sr { background-position: -0px -516px; width: 18px; height: 12px; }
    &.-flag-uk { background-position: -0px -540px; width: 18px; height: 12px; }
    &.-flag-vi { background-position: -0px -552px; width: 18px; height: 12px; }
    &.-flag-ca { background-position: -0px -564px; width: 17px; height: 12px; }
    &.-flag-fo { background-position: -0px -576px; width: 17px; height: 12px; }
    &.-flag-he { background-position: -0px -588px; width: 17px; height: 12px; }
    &.-flag-is { background-position: -0px -600px; width: 17px; height: 12px; }
    &.-flag-br { background-position: -0px -612px; width: 17px; height: 12px; }
    &.-flag-sq { background-position: -0px -624px; width: 17px; height: 12px; }
    &.-flag-da { background-position: -0px -636px; width: 16px; height: 12px; }
    &.-flag-eu { background-position: -0px -648px; width: 16px; height: 12px; }
    &.-flag-no { background-position: -0px -660px; width: 16px; height: 12px; }
    &.-flag-nn { background-position: -0px -672px; width: 16px; height: 12px; }
    &.-flag-se { background-position: -0px -684px; width: 16px; height: 12px; }
    &.-flag-th { background-position: -0px -696px; width: 16px; height: 12px; }

    &.-character-icon {
        background-position: -338px 0px;
        width: 120px;
        height: 120px;
    }

    &.-file-srt {
        background-position: 0px -105px;
        width: 178px;
        height: 119px;
    }

    &.-flag-zh {
        background-image: url(../assets/img/sprites/sprites.png);
        background-position: -302px -153px;
        width: 21px;
        height: 21px;
    }

    &.-flag-es {
        background-image: url(../assets/img/sprites/sprites.png);
        background-position: -432px -187px;
        width: 21px;
    }

    &.-flag-jp {
        background-image: url(../assets/img/sprites/sprites.png);
        background-position: -432px -165px;
        width: 21px;
    }

    &.-flag-pt {
        background-image: url(../assets/img/sprites/sprites.png);
        background-position: -432px -143px;
        width: 21px;
    }

    &.-flag-ru {
        background-image: url(../assets/img/sprites/sprites.png);
        background-position: -432px -121px;
        width: 21px;
    }

    &.-flag-tr {
        background-image: url(../assets/img/sprites/sprites.png);
        background-position: -302px -175px;
        width: 21px;
        height: 21px;
    }

    &.-material-empty {
        background-position: -209px 0px;
        width: 128px;
        height: 152px;
    }

    &.-material-episode {
        background-position: 0px -225px;
        width: 127px;
        height: 103px;
    }

    &.-material-remove {
        background-position: 0px 0px;
        width: 208px;
        height: 104px;
    }

    &.-material-report {
        background-position: -128px -225px;
        width: 97px;
        height: 86px;
    }

    &.-user-blocked {
        background-position: -226px -225px;
        width: 78px;
        height: 84px;
    }

    &.-user-err {
        background-position: -338px -121px;
        width: 93px;
        height: 96px;
    }

    &.-user-mail {
        background-position: -209px -153px;
        width: 92px;
        height: 66px;
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(../assets/img/sprites/sprites@2x.png);
        background-size: 458px 328px;
    }
}

.ewa-sprite-small {
    display:inline-block;
    background-image: url(../assets/img/sprites/sprites@05.png);
    background-repeat: no-repeat;

    &.-flag-cn {
        background-position: -150px -76px;
        width: 12px;
        height: 12px;
    }

    &.-flag-es {
        background-position: -215px -93px;
        width: 12px;
        height: 12px;
    }

    &.-flag-jp {
        background-position: -215px -82px;
        width: 12px;
        height: 12px;
    }

    &.-flag-pt {
        background-position: -215px -71px;
        width: 12px;
        height: 12px;
    }

    &.-flag-ru {
        background-position: -215px -60px;
        width: 12px;
        height: 12px;
    }

    &.-flag-tr {
        background-position: -150px -87px;
        width: 12px;
        height: 12px;
    }
}




