.search-container {
  &.langs {
    min-width: 35rem;
    height: 4rem;

    input {
      width: 100%;
      height: 100%;

      border-radius: 1rem;
      border: none;
      padding: 2rem;
    }
  }
}

#modal-language-form {
  .ewa-form-title {
    margin-top: 3rem;
  }
}
