.ewa-badge {
    &_user {
        display:inline-block;
        height:28px;
        vertical-align: middle;
        line-height: 28px;
        width:28px;
        margin:0 10px;

        background:$color-disabled;
        border-radius:100%;
        color:#fff;

        font-size:12px;
        font-weight:800;
        letter-spacing:0.3px;
        line-height:28px;
        text-align:center;
        vertical-align:middle;

        &.-sm {
            height:22px;
            width:22px;

            font-size:8px;
            line-height:24px;
            letter-spacing:0.54px;
        }

        &.-lg {
            height:74px;
            width:74px;

            font-size:22px;
            line-height:74px;
            letter-spacing:0.55px;
        }

        &.-xl {
            height:100px;
            width:100px;

            font-size:26px;
            line-height:105px;
            letter-spacing:1.13px;
        }
    }

    &_lang {
        position: relative;
        display: inline-block;
        width: 23px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        vertical-align: middle;
        margin: -1px 8px 0 8px;

        border: 1px rgba(0, 0, 0, 0.05) solid;
        border-radius: 100%;
        vertical-align:middle;

        i {
            position: relative;
            display: inline-block;
            width: 21px;
            height: 21px;
            overflow: hidden;
            border-radius: 100%;
            transition: opacity 0.2s;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                background: $color-primary;
                border-radius: 100%;
                opacity: 0;

                transition: opacity 0.2s;
            }
        }

        &.-active {
            &:before {
                content: "";
                position: absolute;
                display: block;
                top: -5px;
                left: -5px;
                height: 31px;
                width: 31px;
                border: 2px $color-secondary solid;
                border-radius: 100%;
                vertical-align: middle;
            }
        }

        &.-disabled {
            opacity: 0.5;

            i {
                filter: grayscale(100%);
            }
        }

        &.-inactive {
            &:hover, &:focus{
                cursor: auto;

                i {
                    opacity: 1;
                }
            }
        }

        &.-checked {
            &:after {
                @extend .ewa-icon, .ewa-icon.-check:before;

                position: absolute;
                right: -8px;
                bottom: -8px;
                height: 16px;
                width: 16px;

                background: $color-smooth;
                border: 2px #fff solid;
                border-radius: 100%;
                color: #fff;

                font-size: 6px;
                line-height: 13px;
                text-decoration: none;
                text-align: center;
            }

            &.-annotated {
                &:after {
                     background: $color-success;
                }
            }
        }

        &:first-of-type {
            margin-left:0;
        }

        &:hover {
            i {
                opacity: 0.6;
            }

            .ewa-coverage {
                display: block;
            }
        }

        .ewa-coverage {
            display: none;
            padding: 4px 7px;
            font-size: .9em;
            line-height: .9em;
            color: #ccc;
            border: rgba(0,0,0, .6);
            border-radius: 5px;
            background-color: rgba(0,0,0, .95);
            position: absolute;
            left: -18px;
        }
    }

    &_warning{
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: -1px 7px 0 0;

        background: #FAC144;
        border-radius: 100%;
        color:#fff;
        font-size:8px;
        line-height:12px;
        text-align:center;
        vertical-align:middle;

        &:before {
            @extend .ewa-icon, .ewa-icon.-exclamation:before;

            display:inline;
            background:none;
            border-radius:0;
            height:auto;
            width:auto;
        }

        &:hover{
            cursor: pointer;
        }

        &.-secondary{
            background-color: $color-error;
            width: 26px;
            height: 26px;
            font-size: 12px;
            line-height: 24px;

            &.-added{
                font-size: 10px;
                line-height: 26px;

                &:before{
                    @extend .ewa-icon, .ewa-icon.-times:before;
                }
            }
        }
    }

    &_check {
        display:inline-block;
        height:12px;
        width:12px;
        margin:-1px 7px 0 0;

        background:#ED5564;
        color:#fff;
        border-radius:100%;
        font-size:6px;
        line-height:12px;
        text-align:center;
        vertical-align:middle;

        &:before {
            display:inline-block;
            content:"";
            background:#fff;
            border-radius:100%;
            height:6px;
            width:6px;
            vertical-align:middle;
        }

        &.-unchecked {
            display:inline-block;
            height:12px;
            width:12px;
            margin:-1px 7px 0 0;

            background:#ED5564;
            color:#fff;
            border-radius:100%;
            font-size:6px;
            line-height:12px;
            text-align:center;
            vertical-align:middle;
        }

        &.-checked {
            background:$color-success;

            &:before {
                @extend .ewa-icon, .ewa-icon.-check:before;

                display:inline;
                background:none;
                border-radius:0;
                height:auto;
                width:auto;
            }
        }

        &.-sm {
            height: 20px;
            width: 20px;
            line-height:20px;
            font-size:8px;
        }

        &.-xs {
            height: 12px;
            width: 12px;
            margin: -1px 7px 0 0;
        }
    }

    &_icon {
        margin:0 10px;
        font-size:14px;

        &.-success {
            color:$color-success;
        }
    }
}
