.bar {
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 60px;

  border-top: solid 1px rgb(216, 216, 216);
  background: white;

  text-align: right;

  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .actions, .info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }

  .info {
    transition: bottom .4s;
    position: relative;
    justify-content: left;
    bottom: -100px;

    &.visible {
      bottom: 0;
    }

    .selected-items {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      padding-left: 10px;
      color: $color-primary;
    }
  }

  .message {
    font-size: 15px;
    font-weight: bold;

    margin-right: 30px;
  }

  .actions {
    margin-right: 30px;
    line-height: 60px;

    .toggle-edit-mode {
      background: white;
      float: right;
      font-size: 16px;
      cursor: pointer;
      transition: all .4s;

      &:hover {
        font-weight: bold;
      }
      .ewa-icon.-pencil {
        font-size: 14px;
        position: relative;
        top: 4px;
        right: 5px;
      }
    }

    .controls {
      transition: bottom .4s;
      position: relative;
      padding: 0 10px;
      bottom: -100px;

      &.visible {
        bottom: 0;
      }

      .control {
        display: inline;
        margin-right: 5px;

        .ewa-btn_round.-danger {
          color: #e80c0c;
        }
      }
    }
  }

  &.activated {
    animation: pulse .9s infinite;
    outline: solid 8px red;
  }
}

@keyframes pulse {
  0% {
    outline-color: red;
  }
  50% {
    outline-color: #FF9800;
  }
  100% {
    outline-color: red;
  }
}
