/* reset styling (prevent conflicts with bootstrap, materialize.css, etc.) */

div {
  &.jsoneditor {
    .jsoneditor-search input {
      height: auto;
      border: inherit;
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
    table {
      border-collapse: collapse;
      width: auto;
    }
    td, th {
      padding: 0;
      display: table-cell;
      text-align: left;
      vertical-align: inherit;
      border-radius: inherit;
    }
  }
  &.jsoneditor-field, &.jsoneditor-value, &.jsoneditor-readonly {
    border: 1px solid transparent;
    min-height: 16px;
    min-width: 32px;
    padding: 2px;
    margin: 1px;
    word-wrap: break-word;
    float: left;
  }
  &.jsoneditor-field p {
    margin: 0;
  }
  &.jsoneditor-value {
    p {
      margin: 0;
    }
    word-break: break-word;
  }
  &.jsoneditor-readonly {
    min-width: 16px;
    color: gray;
  }
  &.jsoneditor-empty {
    border-color: lightgray;
    border-style: dashed;
    border-radius: 2px;
  }
  &.jsoneditor-field.jsoneditor-empty::after, &.jsoneditor-value.jsoneditor-empty::after {
    pointer-events: none;
    color: lightgray;
    font-size: 8pt;
  }
  &.jsoneditor-field.jsoneditor-empty::after {
    content: "field";
  }
  &.jsoneditor-value {
    &.jsoneditor-empty::after {
      content: "value";
    }
    &.jsoneditor-url {
      color: green;
      text-decoration: underline;
    }
  }
}

/* adjust margin of p elements inside editable divs, needed for Opera, IE */

a.jsoneditor-value.jsoneditor-url {
  color: green;
  text-decoration: underline;
  display: inline-block;
  padding: 2px;
  margin: 2px;
  &:hover, &:focus {
    color: #ee422e;
  }
}

div {
  &.jsoneditor td.jsoneditor-separator {
    padding: 3px 0;
    vertical-align: top;
    color: gray;
  }
  &.jsoneditor-field[contenteditable=true] {
    &:focus, &:hover {
      background-color: #FFFFAB;
      border: 1px solid yellow;
      border-radius: 2px;
    }
  }
  &.jsoneditor-value[contenteditable=true] {
    &:focus, &:hover {
      background-color: #FFFFAB;
      border: 1px solid yellow;
      border-radius: 2px;
    }
  }
  &.jsoneditor-field.jsoneditor-highlight, &.jsoneditor-value.jsoneditor-highlight {
    background-color: #FFFFAB;
    border: 1px solid yellow;
    border-radius: 2px;
  }
  &.jsoneditor-field.jsoneditor-highlight-active {
    background-color: #ffee00;
    border: 1px solid #ffc700;
    border-radius: 2px;
    &:focus, &:hover {
      background-color: #ffee00;
      border: 1px solid #ffc700;
      border-radius: 2px;
    }
  }
  &.jsoneditor-value {
    &.jsoneditor-highlight-active {
      background-color: #ffee00;
      border: 1px solid #ffc700;
      border-radius: 2px;
      &:focus, &:hover {
        background-color: #ffee00;
        border: 1px solid #ffc700;
        border-radius: 2px;
      }
    }
    &.jsoneditor-string {
      color: #008000;
    }
    &.jsoneditor-object, &.jsoneditor-array {
      min-width: 16px;
      color: #808080;
    }
    &.jsoneditor-number {
      color: #ee422e;
    }
    &.jsoneditor-boolean {
      color: #ff8c00;
    }
    &.jsoneditor-null {
      color: #004ED0;
    }
    &.jsoneditor-invalid {
      color: #000000;
    }
  }
  &.jsoneditor-tree button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    background: transparent url("../assets/img/jsoneditor-icons.svg");
  }
  &.jsoneditor-mode-view tr.jsoneditor-expandable td.jsoneditor-tree, &.jsoneditor-mode-form tr.jsoneditor-expandable td.jsoneditor-tree {
    cursor: pointer;
  }
  &.jsoneditor-tree button {
    &.jsoneditor-collapsed {
      background-position: 0 -48px;
    }
    &.jsoneditor-expanded {
      background-position: 0 -72px;
    }
    &.jsoneditor-contextmenu {
      background-position: -48px -72px;
      &:hover, &:focus, &.jsoneditor-selected {
        background-position: -48px -48px;
      }
    }
  }
}

tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu {
  background-position: -48px -48px;
}

div {
  &.jsoneditor-tree {
    *:focus {
      outline: none;
    }
    button {
      &:focus {
        /* TODO: nice outline for buttons with focus
        outline: #97B0F8 solid 2px;
        box-shadow: 0 0 8px #97B0F8;
        */
        background-color: #f5f5f5;
        outline: #e5e5e5 solid 1px;
      }
      &.jsoneditor-invisible {
        visibility: hidden;
        background: none;
      }
    }
  }
  &.jsoneditor {
    color: #1A1A1A;
    border: 1px solid #3883fa;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;
    line-height: 100%;
  }
  &.jsoneditor-tree table.jsoneditor-tree {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0;
  }
  &.jsoneditor-outer {
    position: static;
    width: 100%;
    height: 100%;
    margin: -35px 0 0 0;
    padding: 35px 0 0 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &.has-nav-bar {
      margin: -61px 0 0 0;
      padding: 61px 0 0 0;
    }
    &.has-status-bar {
      margin: -35px 0 -26px 0;
      padding: 35px 0 26px 0;
    }
  }
}

textarea.jsoneditor-text, .ace-jsoneditor {
  min-height: 150px;
}

div.jsoneditor-tree {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

textarea.jsoneditor-text {
  width: 100%;
  height: 100%;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline-width: 0;
  border: none;
  background-color: white;
  resize: none;
}

tr {
  &.jsoneditor-highlight {
    background-color: #d3d3d3;
  }
  &.jsoneditor-selected {
    background-color: #d3d3d3;
    button {
      &.jsoneditor-dragarea, &.jsoneditor-contextmenu {
        visibility: hidden;
      }
    }
    &.jsoneditor-first button {
      &.jsoneditor-dragarea, &.jsoneditor-contextmenu {
        visibility: visible;
      }
    }
  }
}

div.jsoneditor-tree button.jsoneditor-dragarea {
  background: url("../assets/img/jsoneditor-icons.svg") -72px -72px;
  cursor: move;
  &:hover, &:focus {
    background-position: -72px -48px;
  }
}

tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
  background-position: -72px -48px;
}

div {
  &.jsoneditor {
    tr, th {
      padding: 0;
      margin: 0;
    }
    td {
      padding: 0;
      margin: 0;
      vertical-align: top;
      &.jsoneditor-tree {
        vertical-align: top;
      }
    }
  }
  &.jsoneditor-field, &.jsoneditor-value {
    font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
    font-size: 10pt;
    color: #1A1A1A;
  }
  &.jsoneditor {
    td, th, textarea {
      font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
      font-size: 10pt;
      color: #1A1A1A;
    }
  }
}

.jsoneditor-schema-error {
  font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
  font-size: 10pt;
  color: #1A1A1A;
  cursor: default;
  display: inline-block;
  /*font-family: arial, sans-serif;*/
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 24px;
}

/* popover */

div.jsoneditor-tree .jsoneditor-schema-error {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  background: url("../assets/img/jsoneditor-icons.svg") -168px -48px;
}

.jsoneditor-schema-error {
  .jsoneditor-popover {
    background-color: #4c4c4c;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    display: none;
    padding: 7px 10px;
    position: absolute;
    width: 200px;
    z-index: 4;
    &.jsoneditor-above {
      bottom: 32px;
      left: -98px;
    }
    &.jsoneditor-below {
      top: 32px;
      left: -98px;
    }
    &.jsoneditor-left {
      top: -7px;
      right: 32px;
    }
    &.jsoneditor-right {
      top: -7px;
      left: 32px;
    }
    &:before {
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      content: '';
      display: block;
      left: 50%;
      margin-left: -7px;
      position: absolute;
    }
    &.jsoneditor-above:before {
      border-top: 7px solid #4c4c4c;
      bottom: -7px;
    }
    &.jsoneditor-below:before {
      border-bottom: 7px solid #4c4c4c;
      top: -7px;
    }
    &.jsoneditor-left:before {
      border-left: 7px solid #4c4c4c;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      content: '';
      top: 19px;
      right: -14px;
      left: inherit;
      margin-left: inherit;
      margin-top: -7px;
      position: absolute;
    }
    &.jsoneditor-right:before {
      border-right: 7px solid #4c4c4c;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      content: '';
      top: 19px;
      left: -14px;
      margin-left: inherit;
      margin-top: -7px;
      position: absolute;
    }
  }
  &:hover .jsoneditor-popover, &:focus .jsoneditor-popover {
    display: block;
    -webkit-animation: fade-in .3s linear 1, move-up .3s linear 1;
    -moz-animation: fade-in .3s linear 1, move-up .3s linear 1;
    -ms-animation: fade-in .3s linear 1, move-up .3s linear 1;
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@-ms-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


/*@-webkit-keyframes move-up {*/

/*from   { bottom: 24px; }*/

/*to { bottom: 32px; }*/

/*}*/

/*@-moz-keyframes move-up {*/

/*from   { bottom: 24px; }*/

/*to { bottom: 32px; }*/

/*}*/

/*@-ms-keyframes move-up {*/

/*from   { bottom: 24px; }*/

/*to { bottom: 32px; }*/

/*}*/

/* JSON schema errors displayed at the bottom of the editor in mode text and code */

.jsoneditor .jsoneditor-text-errors {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffef8b;
  border-top: 1px solid #ffd700;
  td {
    padding: 3px 6px;
    vertical-align: middle;
  }
}

.jsoneditor-text-errors .jsoneditor-schema-error {
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  background: url("../assets/img/jsoneditor-icons.svg") -168px -48px;
}

/* ContextMenu - main menu */

div {
  &.jsoneditor-contextmenu-root {
    position: relative;
    width: 0;
    height: 0;
  }
  &.jsoneditor-contextmenu {
    position: absolute;
    box-sizing: content-box;
    z-index: 99999;
    ul, li {
      box-sizing: content-box;
      position: relative;
    }
    ul {
      position: relative;
      left: 0;
      top: 0;
      width: 128px;
      background: white;
      border: 1px solid #d3d3d3;
      box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3);
      list-style: none;
      margin: 0;
      padding: 0;
      li button {
        position: relative;
        padding: 0 4px 0 0;
        margin: 0;
        width: 128px;
        height: auto;
        border: none;
        cursor: pointer;
        color: #4d4d4d;
        background: transparent;
        font-size: 10pt;
        font-family: arial, sans-serif;
        box-sizing: border-box;
        text-align: left;
        &::-moz-focus-inner {
          padding: 0;
          border: 0;
        }
        &:hover, &:focus {
          color: #1a1a1a;
          background-color: #f5f5f5;
          outline: none;
        }
        &.jsoneditor-default {
          width: 96px;
          /* 128px - 32px */
        }
        &.jsoneditor-expand {
          float: right;
          width: 32px;
          height: 24px;
          border-left: 1px solid #e5e5e5;
        }
      }
    }
    div.jsoneditor-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border: none;
      padding: 0;
      margin: 0;
      background-image: url("../assets/img/jsoneditor-icons.svg");
    }
    ul li ul div.jsoneditor-icon {
      margin-left: 24px;
    }
    div.jsoneditor-text {
      padding: 4px 0 4px 24px;
      word-wrap: break-word;
      &.jsoneditor-right-margin {
        padding-right: 24px;
      }
    }
    ul li {
      button {
        div.jsoneditor-expand {
          position: absolute;
          top: 0;
          right: 0;
          width: 24px;
          height: 24px;
          padding: 0;
          margin: 0 4px 0 0;
          background: url("../assets/img/jsoneditor-icons.svg") 0 -72px;
          opacity: 0.4;
        }
        &:hover div.jsoneditor-expand, &:focus div.jsoneditor-expand {
          opacity: 1;
        }
      }
      &.jsoneditor-selected div.jsoneditor-expand {
        opacity: 1;
      }
      button.jsoneditor-expand {
        &:hover div.jsoneditor-expand, &:focus div.jsoneditor-expand {
          opacity: 1;
        }
      }
    }
    div.jsoneditor-separator {
      height: 0;
      border-top: 1px solid #e5e5e5;
      padding-top: 5px;
      margin-top: 5px;
    }
    button {
      &.jsoneditor-remove {
        > div.jsoneditor-icon {
          background-position: -24px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon {
          background-position: -24px 0;
        }
      }
      &.jsoneditor-append {
        > div.jsoneditor-icon {
          background-position: 0 -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon {
          background-position: 0 0;
        }
      }
      &.jsoneditor-insert {
        > div.jsoneditor-icon {
          background-position: 0 -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon {
          background-position: 0 0;
        }
      }
      &.jsoneditor-duplicate {
        > div.jsoneditor-icon {
          background-position: -48px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon {
          background-position: -48px 0;
        }
      }
      &.jsoneditor-sort-asc {
        > div.jsoneditor-icon {
          background-position: -168px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon {
          background-position: -168px 0;
        }
      }
      &.jsoneditor-sort-desc {
        > div.jsoneditor-icon {
          background-position: -192px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon {
          background-position: -192px 0;
        }
      }
    }
    ul li {
      button.jsoneditor-selected {
        color: white;
        background-color: #ee422e;
        &:hover, &:focus {
          color: white;
          background-color: #ee422e;
        }
      }
      overflow: hidden;
      ul {
        display: none;
        position: relative;
        left: -10px;
        top: 0;
        border: none;
        box-shadow: inset 0 0 10px rgba(128, 128, 128, 0.5);
        padding: 0 10px;
        /* TODO: transition is not supported on IE8-9 */
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        li button {
          padding-left: 24px;
          animation: all ease-in-out 1s;
          &:hover, &:focus {
            background-color: #f5f5f5;
          }
        }
      }
    }
    button {
      &.jsoneditor-type-string {
        > div.jsoneditor-icon {
          background-position: -144px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon, &.jsoneditor-selected > div.jsoneditor-icon {
          background-position: -144px 0;
        }
      }
      &.jsoneditor-type-auto {
        > div.jsoneditor-icon {
          background-position: -120px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon, &.jsoneditor-selected > div.jsoneditor-icon {
          background-position: -120px 0;
        }
      }
      &.jsoneditor-type-object {
        > div.jsoneditor-icon {
          background-position: -72px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon, &.jsoneditor-selected > div.jsoneditor-icon {
          background-position: -72px 0;
        }
      }
      &.jsoneditor-type-array {
        > div.jsoneditor-icon {
          background-position: -96px -24px;
        }
        &:hover > div.jsoneditor-icon, &:focus > div.jsoneditor-icon, &.jsoneditor-selected > div.jsoneditor-icon {
          background-position: -96px 0;
        }
      }
      &.jsoneditor-type-modes > div.jsoneditor-icon {
        background-image: none;
        width: 6px;
      }
    }
  }
  &.jsoneditor-menu {
    width: 100%;
    height: 35px;
    padding: 2px;
    margin: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    background-color: #3883fa;
    border-bottom: 1px solid #3883fa;
    > {
      button, div.jsoneditor-modes > button {
        width: 26px;
        height: 26px;
        margin: 2px;
        padding: 0;
        border-radius: 2px;
        border: 1px solid transparent;
        background: transparent url("../assets/img/jsoneditor-icons.svg");
        color: white;
        opacity: 0.8;
        font-family: arial, sans-serif;
        font-size: 10pt;
        float: left;
      }
      button:hover, div.jsoneditor-modes > button:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.4);
      }
      button {
        &:focus, &:active {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
      div.jsoneditor-modes > button {
        &:focus, &:active {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
      button:disabled, div.jsoneditor-modes > button:disabled {
        opacity: 0.5;
      }
      button {
        &.jsoneditor-collapse-all {
          background-position: 0 -96px;
        }
        &.jsoneditor-expand-all {
          background-position: 0 -120px;
        }
        &.jsoneditor-undo {
          background-position: -24px -96px;
          &:disabled {
            background-position: -24px -120px;
          }
        }
        &.jsoneditor-redo {
          background-position: -48px -96px;
          &:disabled {
            background-position: -48px -120px;
          }
        }
        &.jsoneditor-compact {
          background-position: -72px -96px;
        }
        &.jsoneditor-format {
          background-position: -72px -120px;
        }
        &.jsoneditor-repair {
          background-position: -96px -96px;
        }
      }
      div.jsoneditor-modes {
        display: inline-block;
        float: left;
        > button {
          background-image: none;
          width: auto;
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      button.jsoneditor-separator, div.jsoneditor-modes > button.jsoneditor-separator {
        margin-left: 10px;
      }
    }
    a {
      font-family: arial, sans-serif;
      font-size: 10pt;
      color: white;
      opacity: 0.8;
      vertical-align: middle;
      &:hover {
        opacity: 1;
      }
      &.jsoneditor-poweredBy {
        font-size: 8pt;
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
      }
    }
  }
}

/* Fix button padding in firefox */

/* ContextMenu - sub menu */

table.jsoneditor-search {
  input {
    font-family: arial, sans-serif;
    font-size: 10pt;
    color: #1A1A1A;
    background: transparent;
    /* For Firefox */
  }
  div {
    &.jsoneditor-results {
      font-family: arial, sans-serif;
      font-size: 10pt;
      color: #1A1A1A;
      background: transparent;
      /* For Firefox */
      color: white;
      padding-right: 5px;
      line-height: 24px;
    }
    &.jsoneditor-frame {
      border: 1px solid transparent;
      background-color: white;
      padding: 0 2px;
      margin: 0;
      table {
        border-collapse: collapse;
      }
    }
  }
  position: absolute;
  right: 4px;
  top: 4px;
  border-collapse: collapse;
  border-spacing: 0;
  input {
    width: 120px;
    border: none;
    outline: none;
    margin: 1px;
    line-height: 20px;
  }
  button {
    width: 16px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background: url("../assets/img/jsoneditor-icons.svg");
    vertical-align: top;
    &:hover {
      background-color: transparent;
    }
    &.jsoneditor-refresh {
      width: 18px;
      background-position: -99px -73px;
    }
    &.jsoneditor-next {
      cursor: pointer;
      background-position: -124px -73px;
      &:hover {
        background-position: -124px -49px;
      }
    }
    &.jsoneditor-previous {
      cursor: pointer;
      background-position: -148px -73px;
      margin-right: 2px;
      &:hover {
        background-position: -148px -49px;
      }
    }
  }
}

div {
  &.jsoneditor div.autocomplete {
    &.dropdown {
      position: absolute;
      background: white;
      box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3);
      border: 1px solid #d3d3d3;
      z-index: 100;
      overflow-x: hidden;
      overflow-y: auto;
      cursor: default;
      margin: 0;
      padding-left: 2pt;
      padding-right: 5pt;
      text-align: left;
      outline: 0;
      font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
      font-size: 10pt;
      .item {
        color: #333;
        &.hover {
          background-color: #ddd;
        }
      }
    }
    &.hint {
      color: #aaa;
      top: 4px;
      left: 4px;
    }
  }
  &.jsoneditor-treepath {
    padding: 0 5px;
    overflow: hidden;
    div.jsoneditor-contextmenu-root {
      position: absolute;
      left: 0;
    }
    span {
      &.jsoneditor-treepath-element {
        margin: 1px;
        font-family: arial, sans-serif;
        font-size: 10pt;
      }
      &.jsoneditor-treepath-seperator {
        margin: 2px;
        font-size: 9pt;
        font-family: arial, sans-serif;
      }
      &.jsoneditor-treepath-element:hover, &.jsoneditor-treepath-seperator:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  &.jsoneditor-statusbar {
    line-height: 26px;
    height: 26px;
    margin-top: -1px;
    color: #808080;
    background-color: #ebebeb;
    border-top: 1px solid #d3d3d3;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 10pt;
    > {
      .jsoneditor-curserinfo-label {
        margin: 0 2px 0 4px;
      }
      .jsoneditor-curserinfo-val {
        margin-right: 12px;
      }
      .jsoneditor-curserinfo-count {
        margin-left: 4px;
      }
    }
  }
  &.jsoneditor-navigation-bar {
    width: 100%;
    height: 26px;
    line-height: 26px;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #d3d3d3;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #808080;
    background-color: #ebebeb;
    font-size: 10pt;
    &.nav-bar-empty:after {
      content: 'Select a node ...';
      color: rgba(104, 104, 91, 0.56);
      position: absolute;
      margin-left: 5px;
    }
  }
}
