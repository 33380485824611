.event-scope {
  .recalculate-button {
    margin-left: 10px;
  }

  .change-mode-button {
    margin-right: 30px;
    background-color: #ccc;
    text-transform: uppercase;
  }
}

.event-table {
  tbody {
    td {
      padding: 10px;
      box-sizing: border-box;

      .compare { display: none }

      &:hover {
        .compare { display: inline-block; }
        .default { display: none };
      }
    }
  }

  thead {
    tr:last-child { border-bottom: 1px solid #ccc; }
    tr.secondary {
      th { font-size: 10px; }
    }
    th {
      padding: 15px 10px;

      &.group-label {
        text-align: center;
      }
    }
  }

  thead th, tbody td {
    &.stat-cell { text-align: right; padding-left: 5px; padding-right: 15px; }
    &.-actions {
      text-align: left;

      .ewa-btn_rounded {
        width: 100px;
      }
    }

    &.bordered-left { border-left: 1px solid #ccc; }
    &.bordered-right { border-right: 1px solid #ccc; }
    .ewa-btn_round {
      height: 28px;
      width: 28px;
      padding: 0;
    }
  }

  .bg-event-info {
    background-color: $color-highlight-info;
  }

  .bg-event-stats {
    background-color: $color-highlight-stats;
  }
}

.event-content {
  tbody tr:nth-child(odd) {
    background-color: rgba(204, 204, 204, 0.5);

    &:hover {
      background-color: rgba(204, 204, 204, 0.3);
    }
  }

  td {
    border-right: 1px solid #fff;

    &:last-child {
      boder: none;
    }

    &.row-name {
      vertical-align: middle;
      div { height: 50%; line-height: 22px; padding: 15px 0; }
    }
  }
}
