.ewa-label{
    padding: 3px 5px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.18px;
    line-height: 17px;
    color: #fff;
    font-family: $font-family;
    display: inline-block;

    &.-danger{
        background-color: $color-error;

        &.-secondary{
            margin-right: 37px;
        }
    }
}
