.ewa-profilebox {
    position: relative;
    display: table;
    height: 100%;
    width: 100%;
    table-layout: fixed;
    overflow:hidden;

    background: darken($color-primary, 4.2%);
    border-left:1px lighten($color-primary, 13.1%) solid;
    color: #fff;

    &-inner {
        position: relative;
        display: table-cell;
        height: 100%;
        vertical-align:top;

        &.-center {
            text-align:center;
        }

        &.-middle {
            vertical-align:middle;
        }
    }

    &-wrap {
        padding:90px 0 0 0;
    }

    &-container {
        padding:0 35px;
    }

    &-head {
        position: absolute;
        top: 50px;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;

        a {
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1.03px;
            text-transform:uppercase;

            transition: all 0.2s;

            &:hover, &:focus {
                opacity: 0.8;
                text-decoration:none;
            }

            &:active {
                opacity: 0.7;
            }
        }
    }

    &-badge {
        margin:0 0 30px 0;
    }

    &-username {
        font-size:32px;
        font-weight:normal;
        letter-spacing:0.28px;
        text-transform:capitalize;
    }

    &-role {
        margin:15px 0 0 0;
        opacity:0.6;
        font-size:13px;
        font-weight:bold;
        letter-spacing:1.35px;
        text-transform:uppercase;
    }

    &-email {
        margin:35px 0 0 0;
        font-size:16px;
        font-weight:normal;
        letter-spacing:0.14px;
    }

    &-footer {
        position: absolute;
        left:0;
        right:0;
        bottom:25px;

        a {
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.82px;

            transition: all 0.2s;

            &:hover, &:focus {
                opacity: 0.8;
                text-decoration:none;
            }

            &:active {
                opacity: 0.7;
            }
        }
    }

    &-promo {
        background:#fff;
        height:100%;
        overflow:auto;
    }

    .ewa-editionbox-wrap {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;

        a {
            display: inline-block;
            padding: 2.35rem 3rem;
            margin: 0 0.3rem;
            font-size: 2rem;
            text-transform: uppercase;
            border-bottom: 1px solid #fff;
            text-decoration: none;
            text-align: right;
            color: #fff;
            font-weight: bold;
        }
    }
}
