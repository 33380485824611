.ewa-table {

    &-wrap {
        height:100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &_main {
        position:relative;
        width: 100%;

        &.-trackers {
            tbody {
                tr {
                  border-bottom: 1px solid #ccc;
                  &.install-event {
                    background-color: rgba(156, 204, 101, 0.1)
                  }

                  &.commercial-event {
                    background-color: rgba(255, 111, 0, 0.1)
                  }

                  &:hover {
                    &.install-event {
                      background-color: rgba(156, 204, 101, 0.2)
                    }

                    &.commercial-event {
                      background-color: rgba(255, 111, 0, 0.2)
                    }

                    &.default-event {
                      background-color: rgba(0, 0, 0, 0.1)
                    }
                  }

                  td {
                    vertical-align: top;
                  }
                }
            }
        }

        thead {
            th {
                &.-nowrap {
                    white-space: nowrap;
                }

                 &.-current {
                    background-color: rgba( $color-success, .1 );
                }

                &.-expiring {
                    background-color: rgba( $color-warning, .1 );
                }

                &.-expired {
                    background-color: rgba( $color-danger, .1 );
                }

                padding: 27px 25px;
                z-index:50;

                background:#fff;
                color: $color-smooth;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 1.3px;
                text-transform: uppercase;

                // Temporary fix
                .ewa-dropdown > a {
                    text-transform: capitalize;

                    i {
                        display: inline-block;
                        margin: -1px 0 0 5px;
                        color: $color-smooth;
                        opacity: 0.5;
                    }
                }

                &.-actions {
                    text-align: center;
                }

                &.-origin {
                    width: 400px;
                }
            }
        }

        tbody {

            tr {
                border-bottom: 1px $color-fill solid;

                &.not-renewable {
                    background-color: rgba( $color-warning, .6 )!important;
                }

                &.-xl{
                    background-color: #FAFBFC;
                    border-bottom: none;

                    &:hover, &:focus{
                        background-color: #FAFBFC;

                        td {
                            background-color: transparent !important;
                        }
                    }

                    td{
                        font-size: 22px;
                        line-height: 27px;
                        letter-spacing: 0.2px;
                        color: #818DA6;

                    }
                }

                &.border-test {
                    border-left: 1.5em solid #F012BE90;
                }

                &.border-production {
                    border-left: 1.5em solid #2ECC4090;
                }

                &.-parent {
                    td.-name .ewa-thumbnail {
                        cursor: pointer;
                        @include user-select(none);

                        &:after {
                            @include icon-styles;

                            content: icon-char(chevron-down);
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;

                            background: $color-secondary;
                            color: #fff;
                            opacity: 0;
                            font-size: 8px;
                            text-align: center;

                            transition: all 0.2s;
                        }
                    }

                    &:hover {
                        td.-name .ewa-thumbnail:after {
                            opacity: 0.9;
                        }
                    }

                    &.-open {
                        background:$color-fill;

                        td.-name .ewa-thumbnail:after {
                            opacity: 1;
                            content: icon-char(chevron-up);
                        }
                    }
                }

                &.-chain {
                    display: none;

                    &.-open {
                        background:lighten($color-fill, 4%);

                        display: table-row;
                    }
                }

                &.-disabled {
                    cursor: default !important;
                    pointer-events: none !important;
                    td {
                        background:#FAFAFA;
                        opacity: 0.6;

                        &.-actions {
                            opacity:1;
                            pointer-events: auto;
                        }
                    }
                }

                &.-removed {
                    td {
                        position: relative;
                        background: #FAFBFC !important;
                        filter: grayscale(100%);

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            z-index: 2;
                            background: rgba(#FAFBFC, 0.5);
                        }

                        &.-actions {
                            filter: none;

                            &:before {
                                display: none;
                            }

                            button {
                                display: none;
                            }

                             .-restore {
                                display: inline-block;
                            }
                        }
                    }
                }

                &.-highlighted {
                    background-color: rgba($color-primary, .4);
                }

                &.-current {
                    background-color: rgba( $color-success, .1 );
                }

                &.-expiring {
                    background-color: rgba( $color-warning, .1 );
                }

                &.-expired {
                    background-color: rgba( $color-danger, .1 );
                }

                &.-cancelled {
                    background-color: rgba( $color-disabled, .1);
                    td {
                        color: rgba($color-text, .4);
                    }
                }

                &.assign-subscription-row {
                    text-align: center;
                    cursor: pointer;

                    td {
                        font-size: 32px;
                        color: rgba( $color-disabled, .5);

                        &:hover {
                            color: rgba( $color-disabled, .9);
                        }
                    }
                }
            }

            td {
                height:64px;
                padding: 15px 25px;

                color: $color-text;
                font-size: 14px;
                letter-spacing: -0.18px;

                transition: filter 0.8s ease-in-out;

                > a {
                    color: $color-primary;
                    letter-spacing: 0.14px;
                }

                .ewa-subtitle-download {
                    vertical-align: middle;
                    padding-left: 0.2rem;
                    visibility: hidden;
                }

                .ewa-text-download {
                    vertical-align: middle;
                    padding-left: 0.2rem;
                    visibility: hidden;
                }


                &.-nowrap {
                    white-space: nowrap;
                }

                .-overflow {
                    text-overflow: clip;
                    max-width: 15rem;
                    overflow: hidden;
                }

                &.-name {
                    position: relative;
                    font-weight: bold;
                    min-width: 150px;

                    &.-nowrap{
                        white-space: nowrap;
                    }

                    b {
                        color: $color-primary;
                    }

                    a {
                        color: $color-text;
                    }

                    u {
                        display: block;
                        margin: 1px 0 0 0;
                        color: $color-smooth;
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0;
                        text-decoration: none;
                    }

                    p {
                        margin:0;
                        padding:0;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .-samples {
                        text-align: center;
                        display: flex;
                        position: absolute;
                        height: 100%;
                        right: 0;
                        top: 0;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        i {
                            &:before {
                                font-size: 18px;
                            }
                        }
                        span {
                            display: block;
                            font-style: normal;
                            font-size: 14px;
                            font-weight: bold;
                            position: relative;
                            right: 1px;
                        }
                    }
                }

                &.-lang {
                    text-align: left;
                    vertical-align: middle;
                    line-height: 2rem;

                    a {
                       margin: 2px;
                    }
                    // white-space: nowrap;
                }

                &.-user {
                    padding-left:15px;
                    white-space: nowrap;

                    > s {
                        display:inline-block;
                        padding:0 0 0 10px;
                        color:$color-danger;
                        text-decoration:none;
                    }

                }

                &.-transcription {
                    padding-left:18px;

                    &-label {
                        color: #ccc;
                        font-size: 13px;
                        padding: 5px 10px 5px 0px;
                    }

                    &.-nowrap{
                        white-space: nowrap;
                    }

                    span {
                        margin-left:3px;
                    }
                }

                &.-actions {
                    min-width:150px;
                    text-align: right;
                    white-space: nowrap;

                    > .-restore {
                        display: none;
                    }
                }

                .reasons {
                    font-size: 12px;
                    letter-spacing: -0.15px;
                    color: #8F96A6;
                    margin-top: 4px;
                }

                .-download {
                    font-size: 12px;
                    vertical-align: middle;
                    padding: 0 5px;
                    cursor: pointer;
                    color: $color-smooth;

                    &:hover {
                        color: $color-success;
                    }
                }

                .environment {
                    font-size: 1rem;
                    font-weight: bold;
                    padding-top: 0.3rem;
                }

                &.raw-data {
                    min-width: 500px;
                    height: auto;
                    .raw-data-container {
                        width: 100%;
                    }

                    &.trackers {
                        height: 100%;

                        .ace-jsoneditor {
                            clear: both;
                            min-height: 55rem;

                            .ace-content {
                                min-height: 55rem;
                                clear: both;
                                height: 100%!important;
                            }
                        }
                    }
                }
            }
        }

        thead th, tbody td {
            &.-samples {
                text-align: right;
            }

            &.-link {
                padding: 0 10px;
                width: 10px;
            }

            &.property {
                padding: 15px 0.5rem;
            }
        }

        &.-disabled {
            &:after {
                content:"";

                display:block;
                width:100%;
                height:100%;
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;

                background:#fff;
                opacity:0.6;
            }
        }

        &.-fixed {
            table-layout:fixed;
        }

        &.-hover {
            tbody {
                tr {
                    &:hover {
                        td {
                            background: $color-highlight-default;
                        }

                        td.-name .ewa-text-download, .ewa-subtitle-download {
                            visibility: visible;
                        }
                    }
                }
            }
        }

        &.compact {
            thead th, tbody td {
                padding: 5px 25px;

                input, select, option {
                    font-size: 13px;
                }

                &.validity {
                    display: flex;
                    flex-flow: row nowrap;
                    font-size: 10px;

                    input { width: 120px; }
                }
            }

            &.zebra {
                tr:nth-child(odd) {
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    background: transparentize($color-fill, .3);
                }
            }
        }

        .localized {
            cursor: pointer;

            ul {
                li.language-row {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    border-bottom: 1px solid #ccc;

                    span { flex: 1; }

                    &:last-child {
                        border-bottom: none;
                    }
                }
                .language-name {
                    padding: 3px 0;
                    height: 100%;
                }

                .localized-value {
                    ol {
                        padding-left: 0;

                        li {
                            padding: 2px 0;
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: center;

                            strong {
                                background-color: $color-smooth;
                                padding: 2px 5px;
                                color: #fff;
                                margin: 3px;
                                width: 40px;

                                border-radius: 5px;
                                text-align: right;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
    }

    &-action{
        float: right;

        .ewa-label{
            margin-right: 18px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.flex-table {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    minmax(150px, 4.33fr)
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr)
    minmax(150px, 1.67fr)
    minmax(150px, 3.33fr);

  thead,
  tbody,
  tr {
    display: contents;
  }

  th,
  td {
    padding: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    border-right: .05rem solid transparentize($color-primary, 0.8);

    &:last-child {
        border-right: none;
    }

    &.numeric {
        text-align: left;
    }
  }

  th {
    top: 0;
    position: sticky;
    text-align: left;
    font-weight: normal;
    font-size: 1.5rem;

    border-bottom: .1rem solid transparentize($color-primary, 0.1);
    background: #fff;
    color: darken(#808080, 0.5);
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    color: darken(#808080, 0.5);
    font-size: 1.3rem;

    input {
        font-size: 1.35rem;
        color: #000;
    }
  }

  tr:nth-child(even) td {
    background: #f8f6ff;
  }
}
