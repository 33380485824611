.ewa-segments {
  padding-left: 10px;
  display: block;
  overflow-y: auto;
  height: 100%;

  .ewa-segment {
    label {
      margin-right: 10px;
    }
  }

  .ewa-segment-title {
    color: rgb(110, 110, 110);
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    padding: 10px 0;
    display: block;
    border-bottom: 1px solid #ccc;
  }

  .ewa-form-group {
    label { font-size: 14px; }
  }

  .ewa-plans-title {
    font-size: 16px;
    padding: 10px 0;
    color: rgb(110, 110, 110);
    font-weight: bold;
  }

  .ewa-menu {
    display: inline-block;
    list-style: none;
    padding-left: 0;
  }

  .ewa-plans {
    display: inline-block;
    width: 50%;
    position: relative;
    top: -37px;
  }

  .ewa-stats {
    display: inline-block;
    width: 48%;
    height: 100%;
  }

  .ewa-plan-name {
    width: 300px;
    display: inline-block;
  }

  table {
    width: 100%;

    th {
      color: rgb(110, 110, 110);
      font-weight: bold;
      font-size: 15px;
    }

    td, th {
      padding: 5px 1px;
      line-height: 17px;

      &:last-child {
        font-weight: bold;
      }
    }

    tbody {
      td { line-height: 16px; }
    }

    tfoot {
      td {
        border-top: 1px solid #ccc;
      }

      td {
        font-weight: bold;
      }
    }
  }

  .numeric {
    text-align: right;
  }
}
