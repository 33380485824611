#modal-program {
  .ewa-modal-dialog {
    .ewa-modal-languages {
      text-align: right;
      display: block;
    }

    .modal-section {
      .ewa-form-title {
        font-size: 1.8rem;
        text-align: center;

        margin-bottom: 1rem;
      }

      .reward-terms {
        font-size: 1.6rem;
        font-weight: bold;
        padding: 1rem;
      }

      .condition {
        position: relative;
        border: dotted 0.1rem $color-primary;

        padding: 1rem;
        margin-bottom: 2rem;

        .remove {
          position: absolute;
          right: 1.4rem;
          top: 1.7rem;

          i {
            transition: color ease-in .2s;

            font-size: 2rem;
            cursor: pointer;
            color: $color-primary;

            &:hover {
              color: darken($color-primary, 10%);
            }
          }
        }
      }

      .new-condition {
        text-align: center;

        i {
          transition: color ease-in .2s;
          cursor: pointer;

          font-size: 4rem;
          color: $color-primary;

          &:hover {
            color: darken($color-primary, 10%);
          }
        }
      }

    }
  }
}