.pagination-bar {
  padding: 0 10px;

  position: fixed;
  bottom: 0;

  width: 100%;
  display: block;
  background: white;

  box-shadow: 0 2px 5px black;

  .page {
    transition: background-color,color .3s;
    cursor: pointer;
    display: inline-block;
    text-align: center;

    font-size: 12px;
    padding: 7px 12px;

    background-color: $color-secondary;
    color: black;

    margin: 10px;
    border-radius: 3px;

    &:hover, &.active {
      color: white;
      background-color: $color-primary;
    }

  }
}