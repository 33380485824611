.compilations {
  display: flex;
  flex-direction: column;

  justify-content: center;
  text-align: center;

  .compilation {
    display: flex;
    width: 100%;
    justify-content: center;

    border-bottom: solid 1px lightgray;

    .compilation-actions {
      justify-content: center;
    }

    .compilation-title {
      font-size: 18px;
      font-weight: bold;

      padding: 15px 0;
    }

    .compilation-sticked {
      font-size: 16px;
      font-weight: bold;
      color: green;

      padding: 10px 0;
    }
  }

  .poster {
    width: 350px;
  }

  .ewa-btn_rounded {
    i {
      font-size: 18px;
      left: 5px;
      position: relative;
    }
  }
}

.shelves {
  display: flex;
  flex-direction: column;

  text-align: center;
  align-items: center;
  justify-content: center;

  .shelve {
    width: 100%;

    .materials {
      max-weight: 840px;
      overflow-x: scroll;
      flex-direction: row;
    }

    .compilation-actions {
      margin: 5px;
    }

    .border {
      margin: 5px;
      border-bottom: solid 1px lightgray;
    }

    .compilation-title {
      padding: 15px 0;
      font-size: 18px;
      font-weight: bold;
    }

    .image-container {
      display: flex;
      justify-content: center;

      .poster {
        width: 350px;
      }
    }
  }

  .ewa-btn_rounded {
    i {
      font-size: 17px;
      left: 5px;
      position: relative;
    }
  }
}

.items {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  border-bottom: solid 1px lightgray;

  .material {
    font-size: 14px;
    font-weight: bold;
    word-wrap: break-word;

    margin-left: 15px;

    width: 150px;
    text-align: center;

    .title {
      margin-bottom: 30px;
    }
  }
}

#modal-materials-edit {
  .suggestions {
    position: relative;
    overflow-y: scroll;

    width: 50%;
  }

  .ewa-modal-wrap {
    padding-bottom: 80px;
  }

  .backdrop {
    position: absolute;
  }
}

.languages-select {
  position: absolute;

  top: 6px;
  right: 5px;
  width: 150px;
}

#modal-materials-edit {
  .material {
    position: relative;
  }
  .material-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    margin-bottom: 15px;
  }

  .material-poster {
    margin: 0 auto;

    width: 150px;
    text-align: center;
  }

  .material-options {
    position: absolute;
    right: 15px;
    top: 50%;

    .fa-trash {
      font-size: 18px;
      left: 5px;
      position: relative;
    }
  }

  .search {
    margin-bottom: 20px;
  }

  .form {
    margin-bottom: 20px;
    flex: 1;
    max-width: 250px;
  }
}
