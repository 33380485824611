.nlp {
  margin: 80px 0 20px 40px;
  font-size: 24px;

  .request {
    padding: 40px 80px;
    display: flex;

    .text {
      textarea {
        width: 100%;
        height: 100%;
        min-height: 150px;
        margin-top: 6px;
      }
    }

    .language-select {
      select {
        margin-top: 6px;
        display: flex;
        max-width: 100%;
      }
    }

    .service-loading-indicator {
      &.-loading {
        border: 6px solid #f3f3f3;
        border-top: 6px solid #3498db;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        animation: spin 2s linear infinite;
      }
    }
  }
}
