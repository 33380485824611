.ewa-wrap {
    padding: 60px 0;
}

.ewa-container {
    padding: 20px 60px 80px 60px;
}

.ewa-content {
    height: 100%;
    overflow: auto;
    .inline-link {
        padding: 15px 10px;
        font-size: 1.3em;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        width: 100%;
        display: block;
    }
}

.ewa-no-content {
    text-align: center;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 800px;
    height: 100px;
    span {
        margin: 2px 25px 0 0;
        color: #A1A7B4;
        font-size: 36px;
        line-height: 44px;
    }
}
