/* Main Colors */
$color-primary: #593CCC;
$color-secondary: #0BBDF3;

$color-fill: #EBEFF5;
$color-muted: #F1F3F8;
$color-smooth: #8F96A6;
$color-disabled: #A1A7B4;
$color-grey: #DFE4EB;
$color-gold: #FAC244;
$color-blue: #0b22f3;

$color-error: #FD98A7;
$color-info: #F2FCFF;
$color-success: #00C7AE;
$color-warning: yellow;
$color-danger: $color-error;

$color-bg: #FAFBFC;
$color-text: #181A25;
$color-link: $color-secondary;
$color-link-hover: lighten($color-secondary, 5%);

$color-highlight-info: transparentize($color-success, .9);
$color-highlight-stats: transparentize($color-gold, .9);

$color-highlight-default: #E8FAFF;

/* Fonts */
$font-family: "LatoWeb", sans-serif;
$font-size: 12px;
$font-weight: 400;
$letter-spacing: 0.3px;
$line-height: 1;

/* Screen sizes */
$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1366px;
$screen-xl: 1600px;

$screen-xl-min: $screen-xl;
$screen-lg-max: ($screen-xl-min - 1);

/* Paths */
$path-assets: "./assets/";
$path-assets-relative: "../assets/";
$path-bower: "./bower_components/";
$path-bower-relative: "../../bower_components/";

/* Boostrap Settings */
$grid-gutter-width:10px;
