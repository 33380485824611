.kids-edition {
  *::selection {
    background:rgba($color-primary-kids, 0.8);
    color:#fff;
  }

  *::-moz-selection {
    background:rgba($color-primary-kids, 0.8);
    color:#fff;
  }

  .ewa-btn {
    &_primary {
        color:#fff;
        background: $color-primary-kids;

        &:hover, &:focus {
          background: lighten($color-primary-kids, 10%);
          color:#fff;
        }
    }

    &_secondary {
      @extend .ewa-btn_primary;

      background: $color-primary-kids;
      color:#fff;

      &:hover, &:focus {
          background:lighten($color-primary-kids, 10%);
      }

      &:disabled {
          background:lighten($color-primary-kids, 15%);
      }

      &.-loading {
          background: $color-primary-kids;
      }
    }

    &_default {
      &.-primary {
        color: $color-primary-kids;

        &:hover, &:focus {
            color: $color-primary-kids;
        }
      }
    }

    &_search {
      color: $color-primary-kids;

      &:hover, &:focus {
        color:lighten($color-primary-kids, 20%);
      }
    }
  }

  .ewa-sidebar {
    background: transparentize($color-primary-kids, .1);

    &-item {
      &-icon {
        &.-profile {
          color: $color-primary-kids;
        }
      }
    }
  }

  .bar {
    .info {
      .selected-items {;
        color: $color-primary-kids;
      }
    }
  }

  .ewa-dropdown {
    &-menu {
      > li {
        &.-checkbox {
          label {
            span {
              &:after {
                background:$color-primary-kids;
              }
            }
          }
        }
      }
    }
  }

  .ewa-widebar {
    background-color: transparentize($color-primary-kids, .2);
  }

  .ewa-form {
    &-checkbox {
      input:checked + span {
        background: $color-primary-kids;
      }
    }
  }

  .pagination-bar {
    .page {
      &:hover, &.active {
        background-color: $color-primary-kids;
      }
    }
  }

  .ewa-paginator {
    &-nav {
      &-item {
        &.-active {
          background: $color-primary-kids;
        }
      }
    }
  }

  .ewa-topbar {
    &-nav {
      &-item {
        &.-active {
          color: $color-primary-kids;
        }
      }
    }

    &-search {
      &-form {
        input {
          color: $color-primary-kids;

          &::-webkit-input-placeholder {color:$color-primary-kids;}
          &::-moz-placeholder          {color:$color-primary-kids;}
          &:-moz-placeholder           {color:$color-primary-kids;}
          &:-ms-input-placeholder      {color:$color-primary-kids;}
        }
      }

      &-close {
        color:$color-primary;

        &:hover, &:focus {
          color:lighten($color-primary-kids, 20%);
        }
      }
    }
  }

  .ewa-table {
    &_main {
      tbody {
        td {
          > a {
            color: lighten($color-primary-kids, 7%);
          }

          &.-name {
            b {
              color: lighten($color-primary-kids, 17%);
            }
          }
        }
      }
    }
  }

  .learning-item {
    .card {
      .save {
        background: $color-primary-kids;
      }

      .field {
        .word-model {
          color: $color-primary-kids;
        }
      }

      &.hoverable {
        outline: dotted 3px transparentize($color-primary-kids, .9);

        &:hover {
          background: transparentize($color-primary-kids, .9);
        }
      }
    }

    .voices {
      .voice {
        i {
          color: $color-primary-kids;
        }
      }
    }
  }

  .ewa-profilebox {
    background: darken($color-primary-kids, 4.2%);
    border-left: 1px lighten($color-primary-kids, 13.1%) solid;
  }

  .ewa-loader {
    &_overlay {
      background: rgba($color-primary-kids, 0.2);
    }
  }

  #modal-program {
    .ewa-modal-dialog {
      .modal-section {
        .condition {
          border: dotted 0.1rem $color-primary-kids;

          .remove {
            i {
              color: $color-primary-kids;

              &:hover {
                color: darken($color-primary-kids, 10%);
              }
            }
          }
        }

        .new-condition {
          i {
            color: $color-primary-kids;

            &:hover {
              color: darken($color-primary-kids, 10%);
            }
          }
        }
      }
    }
  }


  .learning-card {
    &-inner {
      .-pencil {
        &:before {
          color: $color-primary-kids;
        }
      }

      .languages {
        button {
          &:hover {
            border: 1px solid $color-primary-kids;
          }
        }
      }
    }

    &.hoverable {
      outline: dotted 3px transparentize($color-primary-kids, .9);

      &:hover{
        background: transparentize($color-primary-kids, .9);
      }
    }
  }

  .learning-card,
  .learning-item {
    button {
      &.difficulty {
        &.active {
          background: $color-primary-kids;
        }
      }
    }
  }
}
