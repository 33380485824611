.native-lang-select {
  float: left;
  margin-right: 30px;
  position: relative;

  &:hover {
    cursor: pointer;

    .selectable {
      display: block;
    }
  }

  .selected {
    width: 66px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ewa-badge_lang {
      margin-right: 0;
    }
  }

  .selectable {
    z-index: 9;
    display: none;
    position: absolute;
    top: 66px;
    margin-left: -17px;
    overflow: hidden scroll;
    max-height: calc(100vh - 66px - 49px);

    .selectable-item {
      width: 100px;
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #EBEFF5;

      .ewa-badge_lang {
        margin-right: 0;
      }
    }
  }

}
