.ewa-sidebar {
    transition: left .3s ease-in;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    position:fixed;

    top:0;
    left:0;
    bottom:0;

    z-index: 100;
    height: 100%;
    width: 6rem;

    background: transparentize($color-primary, .1);
    color: #fff;

    &-edition-icon {
        font-size: 3rem;
        height: 7.1rem;
        padding: 1.8rem 1.5rem;
        border-bottom: 1px solid $color-fill;

        a {
            color: #fff;
            text-decoration: underline;
        }

        i {
            color: #fff;
            margin: auto;
        }
    }

    &.reveal {
        left: 0px!important;
    }

    &-outer {
        position: relative;
        height: 100%;
        margin-left: 6rem;
    }

    &-inner {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-around;
        height: 100%;
    }

    &-item {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        opacity: 0.5;
        transition: opacity 0.2s;

        &-inner {
            text-align:center;
        }

        &-icon {
            display: block;
            font-size: 1.3rem;

            &.-words {
                font-size: 1.5rem;
            }

            &.-users {
                font-size: 1.6rem;
            }

            &.-langs {
                font-size: 3rem;
            }

            &.-profile {
                height: 3rem;
                width: 3rem;
                padding: 0.9rem 0 0 0;

                background: #fff;
                border-radius: 100%;

                color: $color-primary;
                font-size: 1.3rem;
                font-weight: 800;
            }

            &.-compilations {
                font-size: 1.9rem;
            }

            &.-materials {
                font-size: 1.9rem;
            }

            &.-genres {
                font-size: 1.7rem;
            }
        }

        &-text {
            position:relative;
            display:inline-block;
            font-weight:bold;
            letter-spacing: 0.13rem;
            text-transform:uppercase;
            display: none;
        }

        &:hover, &:focus {
            color:#fff;
            opacity: 0.8;
            text-decoration: none;
        }

        &.-active {
            opacity: 1;
        }

        &.-notify &-text {
            &:after {
                content:"";
                position:absolute;
                right:-10px;
                top:-10px;
                display:block;
                width:6px;
                height:6px;
                background: #FAC144;
                border-radius:100%;
            }
        }
    }
}
