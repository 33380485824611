.languages {
    display: inline-block;

    .language {
        width: 130px;
        height: 39px;
        position: relative;
        margin-right: 10px;
        display: inline-block;
        background-size: contain;

        .ru {
            width: 100%;
            height: 100%;
            display: inline-block;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAAnBAMAAABXirOBAAAAG1BMVEUAAAAAAAD////VKx4AOad7mdEAMKJnMmTeKhjA7v8CAAAAAnRSTlMOAgYegZoAAABGSURBVDjLYxBgIAIwMjApEQEURpUNJWWsoUSAAAa2NCJAAgOLCxHAYVQZbZWxlxMBChg4OogADQzMxkQAg1Flg0IZcSU5AOOALml0gBnNAAAAAElFTkSuQmCC") no-repeat;
        }
        .es {
            width: 100%;
            height: 100%;
            display: inline-block;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAAnCAMAAACSel6AAAACKFBMVEUAAAAAAAD+xADGCh7/xgDLHBv6tgKpXQv4wgb/yACkWgy5WQu5qq6fKRWnEhWyRRLasA7vvQrmnATruALyswL8xQH5vADitQC7u8K9o7O4o5/dsxSjVRO0TxO1ShG5Uwz2vwT/ygD7wAD0swC8wM+8u7q/tbC3jZ7JcZmMlXVygXJIPF/kxlWnXFJ7g0x2gUekPUaqmz+uMTbZsi+HMiKfXB3JqBy4nBzxwBihORihHBauHRXDnhSlYxSbOxTtvROLOhOtKhPRqxGPdBGbZBCzYxClOhCaKRDjtA+cQg/ouA6Wag66eAyKPgzYrQubTwt4RgvJkwqpcArKagqpXAl7TwjckAbjnwXdnwTjlQTprwPlpwOahADJx7rKla/HvqvEtamwjpu7fprGuJW6rpTCbY+0o46wiI24kYiwhICnon2zpninoXa4jHTeyHPYv3PLqW80bW9yf2y4kGi6gmd2gWc+SGWheWM4V2J+hWCHiVeVUVdgQFbfvVNgUk7Il0yKiUbMsEWXkD+pRz6sXDmqUTZxIzLNly9kbi9vTy+gLS6sWC3lvSu9kCvLfSqzRimYjSi7aCicOSeoIiXOqyHEeSCujR21Vh2echuXRxvdsRrOjhqPWhrSrRmAVhiggxd7XBe+VhfFcRa7RhZ2dBSdSBKtPRKuNBLhmhHJSxCZcg+FZQ6xQAvUgAnxwAfAVwfWnAbLmwa2gAbapQPNdAPRqQK3mAHCnwBapFq2AAAAAnRSTlMOAgYegZoAAAHNSURBVEjHYmBkoBYAGcVMNcAwahg5hrFSDQANY6MaABrGQjUANIyJagCrYSBb/ISBBBUMY+EQ9BCYYC7gJcjEQqlhLB5OZjbR0wKjzAWcFCg2zCZ4sYy1qaWjzEofaRaKDAMFlyevv6X/UjtPK9t1QC4FholEK7FsdvF29l7l7BIkzCIWpUSBYUGhEaJiG1cHOMycM9+Lg2VTQCAnuYaxiG6LEReydnTn6+JRS0tJ5tges5WFbJexNObYR/L5hrob8ainqidwGGfNosCwPAmgYQuWOBipJmlIxivUqvSQbRgLYEITW+WX83V2mEyW4tGIU5Od3rJIhIVMwxTX60rNDraaV+erXZMuKRkbVi81KYyTXJcpFyTKRfDy8rYVVlRnZOaGN/O0K5HvzX6DFeJc7CEmZdpu8txrRPoMFpLvzS0lqhZAw9jZ7dy4Xbn5lXUkuoXI9SaTWL4ExDB2WdcZIMNUjJnITxpmhsLCMuzsIaay8nLc/GJzDQXITxrSevpNHD5cXLbluvxyy9YK6uk3KLCQa9iUIq0qDtENvdmamsVTwzmlS7UqBVnIL4JAkJNFlIODRZETwiUaULt2YqMaoGqNPqgbLiPDMGq2tgGRZHF23g32TgAAAABJRU5ErkJggg==") no-repeat;
        }
        .tr {
            width: 100%;
            height: 100%;
            display: inline-block;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAAnCAMAAACSel6AAAAAV1BMVEUAAAAAAAAAAADeKRDdIxD/4ADgLw/dHxDhOA7mUgzsdAn6vQP+1wDiPw7ylgb4tQT/5gDnWAvrbwr2rAT/7QDkSw3kRg3pXwvugAj7xgLqaQrseQn0nQUWXcCLAAAAA3RSTlMODQKrSPa1AAABE0lEQVRIx+2Wy46DMAxFoTgm7zdv/v87B2ZUaJcEa9RF7yKOFOXoOpETV4+6IlL9qOqGTJstOlj1LzCORgAZbJ3iPRjHY4ouq3AHBuMAz6mQKdw7M2XPzNqeb8xiGPR6wvflgByvw6DdtHgm9giHu1HZ6zCcFymjYixFKZfhuTqonAqcCZW9Z5u8z6k/rnRwsYPraaJlf9LjeezAu7boAmDWO2sSQFBOwH9hiqQ2ITKmNdPvznghLGXXhymP8MqyiAUwMNoiAE/qZffgmBNQAAsz7KGR/bkbVm/bonKCI5ySXUSKlxYa3EdoCGBGyNBSvbTovDVEMDCOrR2VM2PGjizNBhA/89/8wr6wz4VR9rSk3fYPmwYnhMWcgawAAAAASUVORK5CYII=") no-repeat;
        }
        .zh {
            width: 100%;
            height: 100%;
            display: inline-block;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAAnCAMAAACSel6AAAAArlBMVEUAAAAAAADjChf////kBhPiAg/iAA3jBBHjCxjlISz76+zjEBz1sbTkFiL99fb62Nr3ysz3wMP0qa7saXHkEx/85ef409Xym6DueX/rXGTlJjH//v75z9H0panzoqbpRk/oQkzmMj3nLTjkGyb++vr74OH63N72vL/0uLzsbXXpUVrqTFXpSlPnND/hAAf88PH1tLjwkJbuhYvqYWnmNkH98PD2w8bwi5HwiI7rYGm8cMjtAAAAAnRSTlMOAgYegZoAAAGpSURBVEjH7dbnboMwEADgNL47m5VAgATI3nun6/1frLFDpURQiZEfldoTvwB/OtvnUXmpPCskBdVnReWPY0hgWJZAgtIYiv67U/ennYYbIZTCAEKbfUetFVEJDDUzhmaLXm/u60teGMNRXUmT3sDjRNxamydREOPazWoOCGOdr8KimS2U5YzxLtlIK4SRe8tr/DhO4voYuTFrpsarT8k5DjTIh2GoEmtgSu2Z8i2iyI51pdVep2ENdt55/eOFZ8UMNZX+OOXXXZfprTpr8ayZgaZL7C0xOBytdVN+so1qZmxUky3MRC83DZ/J2A8xL3ZItBgGzl6t1E0ObKyrviTrgnjkyE9TDTLPpujIFnpaC+qyumu+LozMGLWYjJDSSmPS33HN3UJWDDdtiTmQVrQfdO0v5lhOwlZV+5mS2tnLuzZxpVLThw8aGAX3s9uozS53Gm61Yhh4c6XpriBQOnnBCgpgSrMOTIW9HHlCWMPAdnnhAwXEqRafTNNOx5cbeJlzk7Y9ncXRXBpY7hDmGIVH05l3g4FB5a8HgIScI/Ffd3H5x37Gnnrb/gKK1SqN8wi8PwAAAABJRU5ErkJggg==") no-repeat;
        }
        .pt {
            width: 100%;
            height: 100%;
            display: inline-block;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAAnCAMAAACSel6AAAACfFBMVEUAAAAAAAAAAAD/AAAAZgD///8AYgD9AAD3AQAAYQAAaAEpVgDvdHTLzwAKZgDwDgJokwDlMQDnIQD7/P7i5/Pj3wDa2QDS1gDEzAA5fADzBwAjTqenYUrcQ0HwMDDgNwH6AQHm5ADX4wDV3wDS0gDM0gDHzQCQrwDVrgCkrACCogBxmwBrlwBGgQDeTgDpGQD2+/3s8PiuweJ5l81aer30v7zCaT7aOC/2EhS3Sw8XcAbBygT//wH39wGnxgHUwAGWsAHq6QDc3ADZ1QDV0ADPzQDBxAC9vQDWtgChtACrogCHogDUoQDQlgC2jQAwdwAlcwDgbgARaADeXAAAXADcQgDYMQDfKwDjJwDrDADx8vnZ4fC2xufCzub25eWfsdiSp9dFa7UwWq3cq3fhgXOVaUq2fDzObDzKSTqFXTfWRjbUfDTTdzTAdjTmQTTFpTPOcjOSXTPMVzLpNCvvJSK4ghDeMBD4BhDpFA78CA3QVgjlHgYAZQXW3QLM1wDazQDLyQC3twDKtADBsACNqAC6mACtlwDGkgDWjABZiwDUhgCedQClbwAdbgDCZwDaZgCkZACqYAAnUwCuUgDcRwDcOwDlKwDYHwDj7fjH0+mluOTz0NNrjs1lhMI5YbAURKLrmJjvhYXsgXbfknXzcXHwaGrxSEnIUUi6mUPUkTukajvLsTrXhzSAcTTpTTTLpTPHljPPXjPiKjO6lzBGYy7BtyzCVizk2SqQoCbJWyPp7yDKmR3lLBanxxWjtxXWfBS6YRQKbA3r8gbcdATe4gPUxAMsfQPh6ADHvwDcvADhtgCpsgDHmwDalQB2lQCliwDEdwBCdwDJdADOOQAI83pGAAAAA3RSTlMODQKrSPa1AAAClklEQVRIx2JgYmSgEmBkYmBkwQm4uNmZSQFAZ1HPMAY6GcbGyTaJgzqGcfJJCnhrtncFC3FQahhbkICaKK+napOGirLGFA6KDOPUVRPT8ucLsQvk4A/1UfIRosAwNgFRB6saLq4au8MK7Mwc8vYu8mQbxibgtCI1VQYI0sxkgSBj+VYVeTINYwPoYiNv7uSJUszM/BMmc7CzT++2zFduribPsF5FR/E0Py99Zmb9Nj9g2IdqlhZdsm6VIscwznOOtjZrwgXzeHhSkpN5eHj2yJdtqHevCyPHMD43V7HaHGZmHlZWg1kxrKyscszlBXpCHh3spBvGJinmYGubDTZMRDpRmFVYjtliEzNzvws/GYZpq3EKHMmJjAS5zMQY5DL9io2CUhHKgqQbxunt5imhmjtxQoqhiEG0SJRIVFG35Xpxe1+JKWS4TNNVS9Iq069t8Zwko3km8dIJ2V6l5vp6vnV95Bjm3tJ5rHBaxCJj6dgkE6P58esEy7IEg9slyDCM08FaXaK2gJl5yYxY1pnRwjGGwNjMB8zZ3ldcj7wI4AsoZGZexspqONdYmDXOgtlicxgHWRHAJinqz8m5pVKhYjYkaSyIZC/edVKIrKTBwserFaCzr/hyT3oca4IRq+mBnunph2zqT5CUaBFlhrO4hPpaS68LJQtNTZdWt57ftt3J0UYpjLyM3tDYGWCVWSXFzK6gwMwstNNMTNH9+Ckp8oogXdEGFVXrlSVgBZV5q4+quPK6hZNbOOooeutqq+9YlWFunmW2+2xgVbPYVHaSDUOYph4UwsZ1cO9+fg52jnAP56kUVSi8TkgVioYQhVWdNi+kqhNX8tDjoEIlrNNy+kxX8DSOQdY8GMytoOFlGDXbtFRtbQMAwqS4Gz70wjQAAAAASUVORK5CYII=") no-repeat;
        }
    }

    .checked {
        position: absolute;
        top: 8px;
        right: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWBAMAAAA2mnEIAAAAHlBMVEUAAAAaGhodGxsbGRkcHBweHh4nJyccHBwdHR0gICAka1aRAAAACnRSTlMATTtFNAgNLScgHiZ4dAAAAF5JREFUGNNjYCtSggCNAIZkQRgwYHCcwAAB4RIMxgxQwCaCYLMSZHsg2EwiCLaiApSdChKGsoUDFB1g7EIlEbg5LIIOCDNdMO0izGYTQfiFXYIhEe5HUwY2J6jf1QMAcisP9yBBAyUAAAAASUVORK5CYII=") no-repeat;
    }

}
