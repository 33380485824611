.ewa-paginator {
    position:absolute;
    right:0;
    left:0;
    bottom:0;
    min-height: 4.9rem;
    z-index:99;

    background: none;
    border-top: 1px transparentize($color-fill, .5) solid;

    &-outer {
        position:relative;
        height:100%;
        padding-bottom: 4.9rem;
    }

    &-wrap {
        padding:10px 0;

    }

    &-container {
        padding:0 25px;
        position: relative;
    }

    &-action{
        position: absolute;
        right: 35px;
        top: 50%;
        margin-top: -10px;

        a{
            font-size: 16px;
            line-height: 17px;
            letter-spacing: 0.5px;
            font-weight: 600;
            text-align: center;

            &.-a-disabled{
                color: $color-smooth;
            }

            i{
                font-size: 24px;
                display: inline-block;
                margin-right: 10px;
            }

            span{
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    &-nav {
        margin:0 -6px;

        &-item {
            display:inline-block;
            height:28px;
            min-width:28px;
            padding:0 5px;
            margin:0 6px;

            background:#fafafa;
            border-radius:4px;
            color:$color-text;

            font-size:14px;
            font-weight:normal;
            line-height:28px;
            letter-spacing:0.14px;
            vertical-align:middle;
            text-align:center;

            transition:all 0.2s;

            @at-root a#{&} {
                &:hover, &:focus {
                    background: $color-fill;
                    color: $color-text;
                    text-decoration: none;
                }

                &:active {
                    background:darken($color-fill, 2%);
                }
            }

            &.-active {
                cursor:default;
                background: $color-primary;
                color:#fff !important;
            }
        }
    }

    &.-disabled {
        &:after {
            content:"";

            display:block;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;

            background:#fff;
            opacity:0.6;
        }
    }
}
