.ewa-loginbox {
    height:100%;
    width:100%;

    background-image:url(../assets/img/login/bg.png);
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center center;

    &-wrap {
        display:table;
        height:100%;
        width:100%;

        color:#fff;
    }

    &-inner {
        display:table-cell;
        vertical-align:middle;
    }

    &-container {
        width:290px;
        margin:0 auto;
        text-align:center;
    }

    &-logo {

        &-img {
            font-size:49px;
        }

        &-box {
            width:49px;
            margin:-12px;

            span {
                display:inline-block;
                height:8px;
                width:8px;
                margin:12px;
                border-radius:2.4px;
                background:#fff;
            }
        }

        &-text {
            margin:20px 0 0 0;
            font-size:37px;
        }
    }

    &-form {
        margin:100px 0 0 0;

        .ewa-form {
            &-submit {
                margin-top: 20px;
            }
        }
    }
}
