.ewa-widebar {
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    height: 100%;
    width: 275px;

    background: $color-primary;
    color: #fff;

    &-inner {
        display: table;
        height: 100%;
    }

    &-outer {
        position:relative;
        height:100%;
        margin-left:275px;
    }

    &-container {
        display: table;
        height: 100%;
        padding: 0 20px;
    }

    &-head {
        padding: 20px 0;

        &-left {
            display: inline-block;
            padding: 5px 0 0 0;

            a {
                font-size: 14px;
                color: #fff;
                transition: all 0.2s;

                &:hover, &:focus {
                    opacity:0.8;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        &-right {
            display: inline-block;
            float: right;
            text-align: right;
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    &-body {
        padding: 20px 0;
    }

    &-header {
        margin: 20px 0;

        h1, h2, h3, h4 {
            display: block;
            padding: 0;
            margin: 0;
            overflow: hidden;

            font-size: 16px;
            font-weight: normal;
            letter-spacing: -0.05px;
        }

        h2 {

            width: 235px;
            word-wrap: break-word;
        }

        h2.totlal_words {
            margin-top:20px;
            font-weight: bold;
        }

        p {
            margin: 1px 0 0 0;
            padding: 0;

            font-size: 12px;
            font-weight: normal;
            letter-spacing: -0.07px;
        }

    }

    &-image {
        margin: 20px 0;

        &-img {
            background-color: rgba(255, 255, 255, 0.05);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            width: 255px;
            height: 190px;
            margin: 0 -20px;
        }
    }

    &-text {
        font-size: 14px;
        letter-spacing: -0.05px;
    }

    &-select {
        margin: 40px 0 20px 0;

        &-header {
            b {
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 1.3px;
                text-transform: uppercase;
            }

            a {
                float: right;

                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.2px;
            }

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        &-items {
            margin: 15px -8px 0 -9px;
        }

        &-item {
            position:relative;
            display: inline-block;
            height: 32px;
            width: 32px;
            margin: 9px;

            background: rgba(255, 255, 255, 0.05);
            border-radius: 2px;
            color: #fff;

            font-size: 13px;
            font-weight: 600;
            line-height: 33px; // 1Extra
            text-align: center;

            transition: all 0.1s;

            &:hover, &:focus {
                background: rgba(255, 255, 255, 0.1);
                color: #fff;
                outline: none;
                text-decoration: none;
            }

            &.-active {
                cursor:default;
                background:#fff;
                color:$color-primary;
            }

            &.-checked {
                &:after {
                    @extend .ewa-icon, .ewa-icon.-check:before;

                    position: absolute;
                    right: -10px;
                    bottom: -10px;
                    height: 20px;
                    width: 20px;

                    background:$color-success;
                    border: 3px $color-primary solid;
                    border-radius: 100%;
                    color: #fff;

                    font-size: 5px;
                    line-height: 15px;
                    text-decoration: none;
                    text-align: center;
                }
            }
        }
    }

    &-footer {
        display: table-row;
        vertical-align: bottom;
        height: 110px;
        font-size:14px;
        font-weight:bold;
        text-align:center;

        &-row {
            margin:15px 0 0 0;

            &-left {
                float:left;
                text-align: left;
            }
            &-right {
                text-align: right;
            }
        }
    }
}

