.learning-item {

    .image-text-group {
        display: flex;

        .ewa-form-imgdrop {
            width: 100px!important;
            height: 80px!important;

            margin-right: 5px;
        }

        .text-area {
            width: 100%;

            input {
                padding: 22px 20px!important;
            }
        }

        .incorrect-answer {
            right: 8px;
            position: absolute;
            bottom: 10px;
            cursor: pointer;
            color: red;
        }
    }

    .text-input {
        position: relative;
    }

    .input-controls {
        position: absolute;
        top: 10px;
        right: 8px;
        transition: all ease-in .8s;

        .control {
            label {
                cursor: pointer;
            }

            &:hover {
                font-size: 14px;
                text-shadow: 2px 2px 7px;
            }
        }
    }

    .input-controls-row {
        display: flex;
        column-gap: 4px;
        position: absolute;
        top: 10px;
        right: 8px;
        transition: all ease-in .8s;

        .control {
            label {
                cursor: pointer;
            }

            &:hover {
                font-size: 14px;
                text-shadow: 2px 2px 7px;
            }
        }
    }

    &.bounded {
        outline: solid 1px #E040FB;
        outline-offset: 5px;
    }
    .card {
        position: relative;
        margin-bottom: 10px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        background: #fff;
        padding: 20px 95px 20px 70px;

        &.regular {
            min-height: 315px;
        }

        .ewa-form-checkbox {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            &.capitalize {
              text-transform: capitalize;
            }
        }

        .ewa-form-selectgroup {
            &.exercise-behavior {
                i.active {
                    color: $color-primary;
                }
            }
        }

        .message {
            position: relative;
            .ewa-icon {
                &.-trash:before {
                    top: 5px;
                    color: red;
                    left: -24px;
                    cursor: pointer;
                    font-size: 20px;
                    position: absolute;
                }
            }
        }

        .controls-panel-wrapper {
            position: absolute;
            top: 0;
            left: -60px;
            width: 60px;
            height: 100%;

            .controls-panel {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                display: flex;
                opacity: 0;
                overflow: hidden;
                transition: all 0.3s ease-out;
                flex-flow: column nowrap;
                justify-content: space-between;

                .control-block {
                    flex: 1;
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;

                    &.down-block {
                        justify-content: flex-end;
                    }

                    &.up-block {
                        justify-content: flex-start;
                    }

                    .control {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;
                        height: 60px;
                        width: 60px;
                        font-size: 20px;
                        cursor: pointer;
                        opacity: 0.5;
                        transition: opacity 0.3s ease-out;

                        i {
                            padding: 1px;
                            font-size: 14px;

                            &.small-size {
                                font-size: 12px;
                            }
                        }

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .index {
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 41px;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;

            transition: all ease-out .5s;

            span {
                display: inline-block;
                padding: 10px 0;
                font-size: 24px;
                color: #fff;
            }

            .spinner {
                position: absolute;
                transition: all .5s;
                opacity: 0;
                bottom: 10px;

                &.active {
                    opacity: 1;
                    bottom: 40%;
                }
            }
        }

        .exercise-type {
            font-size: 24px;
            color: #fff;

            &-label {
                color: black;
            }
        }

        .switchers {
            display: flex;
            flex-direction: column;
            padding-top: 30px;
            align-items: center;
            height: 100%;
            .ewa-icon {
                color: #fff;
                cursor: pointer;
                padding: 0 0 20px 0;
                transition: color .3s;

                &:hover {
                    color: $color-warning;
                }
            }
        }

        .save {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 36px;
            left: 0;
            transition: background ease .7s;
            background: $color-primary;
            span {
                font-size: 16px;
            }
            &:hover {
                background: $color-secondary;
            }
        }

        &-column {
            width: 29.9%;
            float: left;
            margin-right: 30px;

            &.extended {
                width: 60%;
            }

            .ewa-form {
                &-group {
                    margin: 0 0 2rem 0;

                    .img-preview {
                        position: relative;
                        &:hover span {
                            display: inline-block;
                        }
                        img {
                            width: 70%;
                            height: 100%;
                            border-radius: 50%;
                        }
                        span {
                            cursor: pointer;
                            background: black;
                            opacity: 0.5;
                            position: absolute;
                            left: 0;
                            font-size: 20px;
                            text-align: center;
                            border-radius: 50%;
                            width: 70%;
                            height: 100%;
                            display: none;
                            &:before {
                                content: "Delete photo";
                                color: white;
                                height: 100%;
                                width: 100%;
                                display: flex;
                                position: absolute;
                                left: 0;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
                &-imgdrop {
                    width: 120px;
                    height: 120px;
                    &-upload {
                        border-radius: 50%;
                    }
                    &-preview-img {
                        border-radius: 50%;
                    }
                    &-preview-actions {
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;

                        a {
                            display: inline-block;
                        }

                        label {
                            margin: 0;
                            padding: 0;
                            cursor: pointer;
                            display: inline-block;
                        }

                        input {
                            &.hidden {
                                margin: 0;
                                padding: 0;
                                height: 0;
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
        }

        .label {
            padding: 0;
            color: #A7AFB3;
            font-size: 10px;
            margin-bottom: 18px;
            display: inline-block;
            text-transform: uppercase;

            .fas.fa-link {
                padding-left: 5px;
            }

            &.heading {
                color: black;
                display: block;
                font-size: 14px;
                text-align: left;
                font-weight: bold;

                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;

                .ewa-form-group {
                    margin: 0;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                }

                a {
                    margin-right: 15px;
                    padding-bottom: 0;
                    text-decoration: none;

                    &:hover {
                        color: $color-disabled;
                    }
                }

                .validation {
                    padding: 0;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    color: $color-success;

                    &.invalid {
                        color: transparentize(red, .5);
                    }

                    margin-right: 10px;
                    border-right: 1px solid #ccc;

                    padding-right: 10px;

                    i {
                        margin-left: 5px;
                    }
                }

                label {
                    padding: 0 10px;
                    border-right: 1px solid #ccc;

                    &:first-child { padding-left: 0; }
                    &:last-child { border-right: none; }
                }
            }

            &.editable {
                border: 0;
                width: 100%;
                text-align: left;
            }
        }

        .field {
            position: relative;
            color: #152935;
            font-size: 14px;
            margin-bottom: 18px;

            .word-model {
                cursor: pointer;
                font-size: 16px;
                font-weight: bold;
                color: $color-primary;
                &:hover {
                    color: $color-warning;
                }
            }

            textarea {
                padding: 22px 20px;
                border: solid 1px #D8D8D8;
                width: 100%;
                border-radius: 4px;
                resize: vertical;
                min-height: 90px;
            }

            .area-limit {
                position: absolute;
                right: 0;
                bottom: -15px;
                &.valid {
                    color: grey;
                }
                &.invalid {
                    color: red;
                }
            }

            input {
                padding: 5px 40px 5px 20px;
                border: solid 1px #D8D8D8;
                border-radius: 4px;
                width: 100%;

                &.padded-right {
                    padding: 5px 55px 5px 20px;
                    border: solid 1px #D8D8D8;
                    border-radius: 4px;
                    width: 100%;
                }
            }

            &.correct {
                input, textarea {
                    border-color: #7ED321;
                }
            }
            &.incorrect {
                input, textarea {
                    border-color: #E3909B;
                }
            }
        }

        &.exercise {
            .index {
                background: #B3D6FF;
            }
        }

        &.explanation {
            .index {
                background: #000;
            }

            .translation {
                display: inline-block;
                width: 20%;
                margin-right: 5px;
            }

            .switchers {
                .ewa-icon {
                    &:hover {
                        color: yellow;
                    }
                }
            }
        }

        &.explainwithmeme {
            .index {
                background: greenyellow;
            }

            .translation {
                display: inline-block;
                width: 20%;
                margin-right: 5px;
            }

            .switchers {
                .ewa-icon {
                    &:hover {
                        color: yellow;
                    }
                }
            }
        }

        &.choosecorrectimage {
            .index {
                background: #e08dff;
            }

            .translation {
                display: inline-block;
                width: 20%;
                margin-right: 5px;
            }

            .switchers {
                .ewa-icon {
                    &:hover {
                        color: yellow;
                    }
                }
            }
        }

        &.choosecorrectimagetext {
            .index {
                background: #873da1;
            }

            .translation {
                display: inline-block;
                width: 20%;
                margin-right: 5px;
            }

            .switchers {
                .ewa-icon {
                    &:hover {
                        color: yellow;
                    }
                }
            }
        }

        &.listenandchoosecorrectimage {
            .index {
                background: #10a1c5;
            }

            .translation {
                display: inline-block;
                width: 20%;
                margin-right: 5px;
            }

            .switchers {
                .ewa-icon {
                    &:hover {
                        color: yellow;
                    }
                }
            }
        }

        &.dialog {
            .index {
                background: #243A64;
            }
        }

        &.composesentence {
            .index {
                background: #F2C21A;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.composeword {
            .index {
                background: #F5E18C;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.composephrasebyparts {
            .index {
                background: #999966;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.composewordbyimage {
            .index {
                background: #F0A68B;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.composewordbyvideo {
            .index {
                background: #2EB8FF;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.composephrasebyimage {
            .index {
                background: #439E;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.composephrasebyvideo {
            .index {
                background: #43C19E;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.composephrasebytext {
            .index {
                background: #9E93C7;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.choosemissedletter {
            .index {
                background: #FF9933;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.chooseimagebytext {
            .index {
                background: #EDB5E5;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.choosebyimage {
            .index {
                background: #EFD5E5;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.choosebyvideo {
            .index {
                background: #CC7C7D;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.story {
            .index {
                background: #529E78;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.chooseanswerbytext {
            .index {
                background: #97998A;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.textwithchooseanswers {
            .index {
                background: #E1A2F7;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.chooseiscorrect {
            .index {
                background: #D54607;

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.speechexercise {
            .index {
                background: transparentize(#E8C19E, 0.4);

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.promoexternallink {
            .index {
                background: transparentize(#8e8e8ef5, 0.4);

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.promocontentlink {
            .index {
                background: transparentize(#8e8e8ef5, 0.4);

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.promosubscription {
            .index {
                background: transparentize(#8e8e8ef5, 0.4);

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.promoreferralprogram {
            .index {
                background: transparentize(#8e8e8ef5, 0.4);

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.letter {
            .index {
                background: transparentize(#8e8, 0.4);

                .switchers {
                    .ewa-icon {
                        &:hover {
                            color: transparentize(#000, .7);
                        }
                    }
                }
            }
        }

        &.hoverable {
            cursor: pointer;
            outline: dotted 3px transparentize($color-primary, .9);
            outline-offset: 4px;
            &:hover{
                background: transparentize($color-primary, .9);
            }
        }

        &.selected {
            background: transparentize($color-danger, .6)!important;
        }

        &.edit-mode {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 0 50px;

            .info {
                display: inline-block;
                margin-right: 10px;
                height: 100%;
                font-size: 12px;
                padding: 13px 5px;

                &.type {
                    border-right: 1px solid #ccc;
                    width: 220px;
                }
            }

            .expand {
                display: flex;
                align-items: center;
                justify-content: middle;

                position: absolute;
                top: 0;
                right: 0;

                height: 100%;

                border-left: solid 1px;
                padding: 0 10px;

                background-color: white;
                transition: all .4s;

                i:before {
                    font-size: 20px;
                }

                &:hover {
                    i:before {
                        font-weight: bold;
                    }
                }
            }

            .icon-type, .icon-behavior {
                margin: 0 5px 0 0;
                width: 30px;

                text-align: center;
                box-sizing: border-box;

                .exercise-type, .exercise-behavior {
                    color: transparentize(#000, .5);
                }

                .exercise-behavior {
                    font-size: 2rem;
                    color: transparentize(#67bab9, .3);
                }
            }
        }

        &:hover {
            .controls-panel {
                opacity: 1;
                width: 60px;
            }
        }
    }
    .voices {
        display: block;
        width: 100%;
        float: left;

        .mic {
            color: black!important;
            padding-left: 5px;
        }

        .voice {
            margin: 5px 0;
            display: flex;
            align-items: center;

            i.show-aliases{
                color: black;
                &.active {
                    color: $color-primary;
                }
            }

            &.excluded {
                display: none;
            }

            i {
                color: $color-primary;
                padding-right: 5px;
                cursor: pointer;

                &.exclude-phrase {
                    display: none;
                    padding-left: 5px;
                }
            }

            &.playing {
                pointer-events: none;
                cursor: default;

                i { color: $color-disabled; }
            }

            &:hover {
                i.exclude-phrase { display: inline-block; color: $color-smooth; }
            }
        }
    }
}

.ewa-form-group {
    &.video-group {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;

        .video-preview {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        .video-status {
            font-size: 14px;

            &.success {
                color: $color-success;
            }

            &.failure {
                color: $color-danger;
            }
        }

        .video-controls {
            display: flex;
            column-gap: 5px;
            align-items: center;
        }

        .thumbnail-box {
            width: 280px;
            height: 150px;
            background: transparentize(#ccc, .3);
            border: 1px solid #ccc;
            margin: 2px;
            cursor: pointer;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .playback {
                font-size: 45px;
                color: transparentize(#000, .7)
            }
        }

        .ewa-form-input {
            border: none;
        }
    }

    .block-label {
        color: transparentize(#000, .3);
        font-size: 11px;
        padding: 1px 0 10px 3px;

        i {
            padding-right: 4px;
        }

        span {
            text-transform: uppercase;
        }
    }
}

#modal-builder {
    .voices {
        .fas {
            margin-right: 10px;
            cursor: pointer;
        }
    }
    &.inactive {
        z-index: 1040;
    }
    .types {
        border-bottom: solid 1px #ccc;
        transition: height .3s;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 0px;

        font-size: 18px;
        text-align: center;

        .type {
            padding: 5px;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
            &.selected {
                font-weight: bold;
            }
        }

        &.expanded {
            height: 250px;
            overflow-y: scroll;
        }
    }
    .ewa-form {
        width: 100%;
        margin: 0;
    }

    .ewa-form-group {
        width: 100%;
        margin: 10px 0;
    }

    .ewa-form-input {
        padding: 5px 20px 5px 0;

        &.hidden {
            display: none;
        }
    }

    .ewa-form-add_translation {
        display: inline;
        position: absolute;
        top: -2px;
    }
    .rows {
        display: flex;
        flex-direction: column;
        .row {
            position: relative;
            display: flex;
            width: 100%;

            border-radius: 3px;
            border-bottom: solid 1px #ccc;

            padding-left: 5px;
            padding-bottom: 10px;
            margin-bottom: 10px;

            .origin {
                cursor: pointer!important;

                &:hover input {
                    cursor: pointer;
                    font-weight: bold;

                }
            }

            &.selected .origin input {
                color: #8aff8a;
                cursor: pointer;
            }

            .col {
                padding-bottom: 35px;
                display: flex;
                width: 100%;

                position: relative;

                &.basis10 {
                    flex-basis: 10%;
                }
                &.basis30 {
                    flex-basis: 30%;
                }
                &.basis100 {
                    flex-basis: 100%;
                }

                .actions {
                    position: absolute;
                    bottom: 0px;
                    left: -3px;
                }

            }
        }
        .ewa-btn_round {
            height: 29px;
            width: 29px;
            top: 3px;
        }
        .ewa-btn_secondary {
            margin-top: 55px;
        }
    }
    .ewa-form-imgdrop {
        width: 100px!important;
        height: 100px!important;
    }

    .items {
        .remove {
            right: 4px;
            top: 42%;
        }
        .item {
            clear: inherit;
            display: flex;
        }
    }

    .suggestions {
        position: relative;
    }
}

#modal-edit-replicas {
    h6 {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        border-bottom: solid 1px;
        padding-bottom: 20px;
    }

    .replicas {
        margin-bottom: 30px;
    }

    .replica {
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 15px;

        &.selected {
            font-weight: bold;
        }
    }
}

#modal-translate {
    .text-label {
        font-size: 15px;
    }
    .text-div {
        font-size: 20px;
    }
    .text-input {
        width: 50%;
    }
    .translate-select {
        background-color: #fff;
        background-image: none;
        border: none;
        font-size: 15px;
        border-bottom: 1px solid hsla(220, 5%, 87%, .3);
        box-shadow: none;
        height: 34px;
        float: right;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}

#modal-voice {
    .text-label {
        font-size: 15px;
    }
    .text-div {
        font-size: 20px;
    }
    .text-input {
        width: 50%;
    }
    .translate-select {
        background-color: #fff;
        background-image: none;
        border: none;
        font-size: 15px;
        border-bottom: 1px solid hsla(220, 5%, 87%, .3);
        box-shadow: none;
        height: 34px;
        float: right;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}
