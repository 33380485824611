.ewa-iphone {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &-screen {
        background: white;
        position: absolute;
        overflow: hidden;
        text-align: left;

        img {
            width: 100% !important;
        }

        &.-black {
            background: #000;
        }
    }

    &-addon {
        position: absolute;
    }

    &.-six {
        width: 230px;

        &:after {
            content: "";
            display: block;
            padding-top: 176%; /* aspect ratio 1,7547619047619057 */
        }

        .ewa-iphone-screen {
            border: 2px #000 solid;
            border-radius: 2px;
            top: 43px;
            left: 33px;
            width: 161px;

            &:before {
                position: absolute;
                content: "";
                display: block;
                padding-top: 177.78%; /* aspect ratio 1,778666666666667 */
            }
        }

        &.-gold {
            background-image: url(../img/iphone/iphone-6-gold.png);
        }

        &.-black {
            background-image: url(../img/iphone/iphone-6-black.png);
        }

        &.-silver {
            background-image: url(../img/iphone/iphone-6-silver.png);
        }

        @media (min-width: $screen-xs-min) {
            width: 271px;

            .ewa-iphone-screen {
                top: 51px;
                left: 39px;
                width: 189px;
            }
        }

        @media (min-width: $screen-sm-min) {
            width: 335px;

            .ewa-iphone-screen {
                top: 63px;
                left: 48px;
                width: 234px;
            }
        }

        @media (min-width: $screen-xl-min) {
            width: 375px;

            .ewa-iphone-screen {
                top: 71px;
                left: 54px;
                width: 259px;
            }
        }

    }

    &.-center {
        margin: 0 auto;
    }
}