.ewa-notice {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width:100%;
    background:$color-fill;


    &.-complex {
        text-align:right;
        flex: 1;
    }

    &-col {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0px 15px;

        &.-email, &.-material-access, &.-stats, &.-subscription-status {
            display: flex;
            flex-flow: row nowrap;

            i {
                color: #ccc;
                display: inline-block;
                font-size: 20px;
                vertical-align: middle;
                margin: 3px 5px 0 0;

                &.no-margins {
                    margin: 0;
                }

                &.all-margins {
                    margin: 3px;
                }

                &.small {
                    font-size: 14px;
                }

                &.micro {
                    font-size: 6px;
                }
            }

            input, span {
                font-size: 14px;
            }

            &.-slim {
                padding: 0;
            }
        }

        &.-labeled {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &.-material-access, &.-stats {
            cursor: pointer;

        }

        &.socnet {
            font-size: 18px;
            text-transform: capitalize;
            color: $color-smooth;
        }
    }

    &-bucket, &-geo {
        text-align: right;
        border-left: 1px solid #ccc;
        height: 100%;
        line-height: 67px;
        padding-right: 20px;
        padding-left: 10px;
        background-color: rgba(204, 204, 204, 0.24);

        width: auto;
        font-size: 22px;
        color: #8F96A6;
        float: right;

        strong { font-weight: bold; }


        .slot { padding-left: 4px; font-weight: normal; }
    }

    &-geo {
        background-color: transparent;
        i { margin-right: 5px; }
    }

    &.-info {
        background:$color-info;
        .counter {
            color:$color-smooth;
            font-size:22px;
        }
    }

    &.-annotation {
        border-top: 1px solid rgba(204, 204, 204, 0.5);
    }

    .unsubscribe {
        color: $color-danger;
    }
}

.ewa-error-message {
    display: block;
    width: 100%;
    padding: 5px;
    font-size: 14px;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
