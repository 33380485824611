.ewa-fullbox {
    display:table;
    padding:20px;
    height:100%;
    width:100%;

    &-inner {
        display:table-cell;
        vertical-align:middle;
        text-align:center;
    }

    &-image {
        margin:0 0 45px 0;
    }

    &-text {
        font-size:20px;
        font-weight:500;
        letter-spacing:0.33px;

        &.-disabled {
            color:$color-disabled;
        }
    }

    &-actions {
        margin:40px 0 0 0;
    }

    &.-dashed &-inner {
        border:2px $color-fill dashed;
        border-radius:2px;
    }

    &.-secondary &-text{
        color: $color-disabled;
    }
}
