html {
    width: 100%;
    height: 100%;
}

body {
    min-width: 100%!important;
    height:100%;
    margin:0;
    padding:0;

    background:$color-bg;
    font-family:$font-family;
    font-size:$font-size;
    letter-spacing:$letter-spacing;
    line-height:$line-height;
    color:$color-text;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color:$color-link;
    cursor:pointer;

    &:hover, &:focus {
        color:$color-link-hover;
        text-decoration:underline;
    }
}

* {
    &:focus {
        outline:none !important;
    }
}

*::selection {
    background:rgba($color-primary, 0.8);
    color:#fff;
}
*::-moz-selection {
    background:rgba($color-primary, 0.8);
    color:#fff;
}

.rtl-direction {
    direction: rtl;
}

.width-50 {
    width: 50%;
    float: left;
}

.width-33 {
    width: 33.3%;
    float: left;
}

.clearfix {
    clear: both;
    float: none;
}

.sidebar-toggler {
    font-size: 18px;
    cursor: pointer;
    display: none;

    color: black;

    position: absolute;
    right: 20px;
    top: 20px;

    z-index: 200;

}

@media (max-width: 1050px) {
    body {
        .sidebar-toggler {
            display: block;
        }
        .uiview {
            .ewa-topbar {
                position: relative;
            }
            .ewa-topbar-container {
                padding: 0;
                display: flex;
            }
            .ewa-topbar-nav {
                width: 100%;
                float: none;
                flex-grow: 1;
                padding: 2rem 4rem;
                margin: 0;
                text-align: center;
                line-height: 1;

                &-item {
                    margin: 0 0.7rem;
                    font-size: 1.2rem;
                    line-height: 1;
                }
            }
            .ewa-topbar-actions {
                width: 100%;
                float: none;
                margin: 0;
                padding: 0 1rem;
                text-align: center;
                background: #ebeff5;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }
            .ewa-sidebar-outer {
                margin: 0;
                overflow: auto;
            }
            .ewa-sidebar {
                left: -6rem;
                text-align: center;
            }

            .ewa-topbar-outer {
                position: relative;
                height: 100%;
                padding-top: 5rem;

                &.info-outer {
                    padding-top: 0rem;
                }

                .ewa-notice {
                    &.-info {
                        margin-top: 1rem;
                    }
                }
            }
        }

        table, .ewa-table_main {
            tbody td, tbody th, tbody tr {
                padding: 0.5rem 1rem;
            }

            tbody td, tbody th {
                &:first-child {
                    padding-left: 1rem;
                }

                button {
                    padding-right: 0.4rem;

                    span {
                        display: none;
                    }
                }
            }

            tr {
                &.assign-subscription-row {
                    td {
                        text-align: left;
                    }
                }
            }
        }

        .ewa-notice {
            display: flex;
            flex-flow: row wrap;
            width: 37rem;
            margin-left: .25rem;
            margin-top: 5rem;
            justify-content: flex-start;
            align-items: flex-start;

            &-col {
                width: 50%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                font-size: 1.1rem;
                min-height: 4rem;
                border-bottom: 0.1rem solid #ccc;
                padding: 0 1rem;

                &.-material-access,
                &.-stats {
                    width: 50%;
                }

                &.-email {
                    width: 100%;
                }

                input {
                    padding: 0;
                    border: none;
                }
            }
        }
    }

    .mobile-hidden {
        display: none !important;
        visability: hidden;
    }
}
