/* Do not remove the comments below. It's the markers used by wiredep to inject
   sass dependencies when defined in the bower.json of your dependencies */

// Commons
@import "scss/variables";
@import "settings/variables";

// Plugins
@import "scss/jsoneditor";

// Base
@import "scss/colors";
@import "scss/fonts";
@import 'scss/fontawesome/fontawesome';
@import "scss/alignement";
@import "scss/bootstrap";
@import "scss/base";
@import "scss/icons";
@import "scss/sprites";
@import "scss/wraps";
@import "scss/snippets";

// Components
@import "scss/breadcrumbs";
@import "scss/learning";
@import "scss/experiments";
@import "scss/programs";
@import "scss/autocomplete";
@import "scss/exercises";
@import "scss/badges";
@import "scss/languages";
@import "scss/buttons";
@import "scss/forms";
@import "scss/modals";
@import "scss/typeahead";
@import "scss/dropdown";
@import "scss/clips";
@import "scss/dictors";

@import "scss/sidebar";
@import "scss/controlbar";
@import "scss/paginationbar";
@import "scss/widebar";
@import "scss/topbar";
@import "scss/native-lang-select";

@import "scss/iphone";
@import "scss/loginbox";
@import "scss/profilebox";
@import "scss/fullbox";
@import "scss/tables";
@import "scss/word";
@import "scss/notice";
@import "scss/paginator";
@import "scss/thumbnail";
@import "scss/loader";
@import "scss/labels";
@import "scss/segments";
@import "scss/events";
@import "scss/plans";
@import "scss/properties";
@import "scss/onboarding";
@import "scss/book";
@import "scss/nlp";

// pages
@import "scss/langs";
@import "scss/compilations";

// global overrides
@import "scss/app";
@import "settings/layout";
@import "settings/buttons";

// apply skin for edition
@import "scss/skin";
